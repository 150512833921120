import { Injectable } from "@angular/core"
import { Faq } from "src/app/lib/interfaces/faq/faq.model";
import { env } from "src/environments/env";
import { SK } from "../../util.service";
import { ApiService } from "../api.service";

@Injectable()
export class FaqApiService extends ApiService {
  async getFaqs(): Promise<Faq[]> {
    return new Promise(async resolve => {
      const lang = await this.storage.get(SK.APP_LANG)
      this.getQueryWithParams(env.apiRouteGetFaqs, await this.parseQueryParams({ lang }))
      .subscribe(res => resolve(res.data))
    })
  }
}