import { Component, Input } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-user-rating',
  templateUrl: './user-rating.component.html',
  styleUrls: ['./user-rating.component.scss'],
})
export class UserRatingComponent {
  @Input() userRating: number
  @Input() interactable: boolean
  @Input() serieName: boolean
  @Input() totalRatings: number

  starIndex: number
  constructor(private popoverController: PopoverController) {}

  onRateButtonPressed() {
    if (this.userRating > 0) {
      this.popoverController.dismiss({
        starPressed: this.userRating,
      })
    }
  }
}
