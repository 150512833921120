import { createFeatureSelector, createSelector } from "@ngrx/store"
import { EpubReaderState } from "./epub-reader.state"

export const selectEpubReader = createFeatureSelector<EpubReaderState>("epubReader")

export const selectEpubLang = createSelector(
  selectEpubReader,
  (state: EpubReaderState) => state.lang
)

export const selectPercent = createSelector(
  selectEpubReader,
  (state: EpubReaderState) => state.percent
)

export const selectIsSwiping = createSelector(
  selectEpubReader,
  (state: EpubReaderState) => state.isSwiping
)

export const selectPosition = createSelector(
  selectEpubReader,
  (state: EpubReaderState) => state.position
)

export const selectIsReady = createSelector(
  selectEpubReader,
  (state: EpubReaderState) => state.isReady
)

export const selectLocations = createSelector(
  selectEpubReader,
  (state: EpubReaderState) => state.locations
)

export const selectFontSize = createSelector(
  selectEpubReader,
  (state: EpubReaderState) => state.fontSize
)