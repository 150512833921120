import { Injectable } from "@angular/core"
import { Editorial } from "src/app/lib/interfaces/editorial/editorial.model"
import { env } from "src/environments/env"
import { ApiService } from "../api.service"

@Injectable()
export class EditorialApiService extends ApiService {
  async getEditorials(): Promise<Editorial[]> {
    return new Promise(resolve => {
      this.getQuery(env.apiRouteEditorials).subscribe(res => resolve(res.data))
    })
  }

  getEditorial(editorialId: number): Promise<Editorial> {
    return new Promise(resolve => {
      this.getQuery(`${env.apiRouteEditorial}/${editorialId}`)
      .subscribe(res => resolve(res.data))
    })
  }
}