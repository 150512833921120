<ion-icon [color]="color" [size]="size" *ngIf="rating >= starIndex" name="star" (click)="starButtonPressed()">
</ion-icon>
<ion-icon
  [color]="color"
  [size]="size"
  *ngIf="rating >= starIndex - 0.5 && rating < starIndex"
  name="star-half"
  (click)="starButtonPressed()"
>
</ion-icon>
<ion-icon
  [color]="color"
  [size]="size"
  *ngIf="rating < starIndex - 0.5"
  name="star-outline"
  (click)="starButtonPressed()"
>
</ion-icon>
