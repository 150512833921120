export interface UserChapterStatusState {
  id: number;
  chapterID: number;
  like: boolean;
  completedDateTime: Date;
  readed: boolean;
  currentPage: number;
  isLoading: boolean;
  isLoaded: boolean;
  currentSecond: number;
  currentPercentage: number;
}

export const initialState: UserChapterStatusState = {
  id: null,
  chapterID: null,
  like: false,
  completedDateTime: null,
  readed: false,
  currentPage: null,
  isLoading: false,
  isLoaded: false,
  currentSecond: 0,
  currentPercentage: 0
}
