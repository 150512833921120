import { createFeatureSelector, createSelector } from '@ngrx/store'
import { VolumeState } from './volume.state'

export const volumeSelector = createFeatureSelector<VolumeState>('volume')

export const selectVolume = createSelector(
  volumeSelector,
  (state: VolumeState) => state.volume
)

export const selectVolumeLoaded = createSelector(
  volumeSelector,
  (state: VolumeState) => state.isLoaded
)




