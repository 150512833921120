import { Injectable } from "@angular/core"
import { Collection } from "src/app/lib/interfaces/serie/collection.model"
import { env } from "src/environments/env"
import { SK } from "../../util.service"
import { ApiService } from "../api.service"
import { String } from "typescript-string-operations"
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto"
import { Serie } from "src/app/lib/interfaces/serie/serie.model"

@Injectable()
export class CollectionApiService extends ApiService {
  getCollections(): Promise<Collection[]> {
    return new Promise(async resolve => {
      const userId = await this.storage.get(SK.USER_ID)
      if (!userId) return resolve([])

      this.getQuery(env.apiRouteGetCollections).subscribe(resolve)
    })
  }

  async addSerieToCollection(serieId: number, collectionId: number): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteAddSerieToCollection, collectionId), { serieId })
      .subscribe(resolve)
    })
  }

  async createCollection(name: string): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(env.apiRouteCreateCollection, { name }).subscribe(resolve)
    })
  }

  async getCollectionSeries(collectionId: number, findOptions: FindOptionsDto): Promise<Collection> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(env.apiRouteGetCollectionSeries, collectionId), await this.parseQueryParams(findOptions))
      .subscribe(resolve)
    })
  }

  async updateCollection(collectionId: number, newName: string): Promise<any> {
    return new Promise(resolve => {
      this.updateQuery(String.format(env.apiRouteUpdateCollection, collectionId), { name: newName }).subscribe(resolve)
    })
  }

  async deleteSerieFromCollection(collectionId: number, series: Serie[]): Promise<Collection> {
    return new Promise(resolve => {
      this.updateQuery(String.format(env.apiRouteDeleteSerieFromCollection, collectionId), { series }).subscribe(resolve)
    })
  }

  async deleteCollection(collectionId: number): Promise<boolean> {
    return new Promise(resolve => {
      this.removeQuery(String.format(env.apiRouteDeleteCollection, collectionId)).subscribe(resolve)
    })
  }
}