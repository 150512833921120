import { common } from "./env.common"

export const env = {
  ...common,
  production: true,
  apiUrl: 'https://api.ksuki.com',
  webUrl: 'https://ksuki.com',
  appUrl: 'https://app.ksuki.com',
  apiKey: '7WbVPEOO3vSpX8gx6sXZ8dPQs8H2navE',
  oneSignalAppId: '947684f4-13c8-4206-a0b9-156a4be28991',
  androidProjectId: '877575876548',
  foveaUrlValidator:
    'https://validator.fovea.cc/v1/validate?appName=com.ontinet.volumes&apiKey=d3d2e19b-9e9f-46f1-9e03-dff78f80fd00',
  stripeId:
    'pk_live_51JVCbbKSj0POhV0twY9IjI9PNERyXJZ5bX8TLCxPjrc9Ym1nLwQHbsis8KHvqIkp0pxYZcDuNuLi6SytTaui1M8x00qNaNjV0I',
  emailCryptoSecretKey: 'WF9NDpWn6TX3Vth1DiQGnlsDDUEz6Ens',
  subscriptionProductId: 'ksuki.infinity',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.ontinet.comics',
  appleStoreUrl: 'https://apps.apple.com/es/app/ksuki/id1546598377',
  webClientId: '437451478170-dablt5dkbhv8au8ng8jdl3it72sco8o6.apps.googleusercontent.com',
  apiKeySkipMaintenance: 'Ny6vez4qdCv3HbJbMTbNVvMSWNEGgSKF',
  revenueCatApiKey: 'sk_VoqawEXxGHgcZOdGhjorSczJTEqfk',
  revenueCatAndroidApiKey: 'goog_tJHyKoBjEkIEGDujvFdIsTVOAIZ',
  revenueCatAppleApiKey: 'appl_iJHGRKyRVfudmXHbEJnyfeBnVaI',
}
