import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { UtilService } from '../services/util.service'

@Injectable({
  providedIn: 'root',
})
export class IsMobileGuard  {
  constructor(public utilService: UtilService, private router: Router) {}
  canActivate(): boolean {
    const isDesktop = this.utilService.isDesktop()

    if (isDesktop) {
      this.router.navigate(['main/tabs/home'])
    }
    return !isDesktop
  }
}
