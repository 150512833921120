import { Injectable } from '@angular/core'
import { LocalizationService } from './localization.service'
import { LoadingController } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loadingObject: HTMLIonLoadingElement
  loading: boolean = false
  autoHide: boolean = false

  constructor(public localizationService: LocalizationService, public loadingController: LoadingController) {}

  public async show(message: string = 'WORD.LOADING') {
    this.loading = true
    let i18nMessage = message !== '' ? await this.localizationService.i18n(message) : ''
    if (!this.loadingObject) {
      this.loadingObject = await this.loadingController.create({
        message: i18nMessage,
        spinner: 'crescent',
        showBackdrop: true,
      })
      this.loadingObject.present().then(() => {
        this.loading = false
        if (this.autoHide) {
          this.loadingObject.dismiss()
        }
      })
    }
  }

  public async hide() {
    if (!this.loadingObject && this.loading) {
      this.autoHide = true
    } else if (this.loadingObject) {
      await this.loadingObject.dismiss()
      this.loadingObject = null
    }
  }
}
