export enum AUTHOR_ROLE {
  WRITER = 'writer',
  ARTIST = 'artist',
  COLORIZER = 'colorizer',
  INKER = 'inker',
  TRANSLATOR = 'translator',
  ASSISTANT = 'assistant',
  ADVISER = 'adviser',
  ORIGINAL_IDEA = 'original_idea',
  EDITOR = 'editor',
}

export enum AUTHOR_LEVEL {
  UNVERIFIED = 0,
  VERIFIED = 1,
  GOLD = 2,
  PLATINUM = 3,
  DIAMOND = 4,
}

export interface Author {
  id: number
  name: string
  twitterURL?: string
  pixivURL?: string
  youtubeURL?: string
  twitchURL?: string
  artstationURL?: string
  instagramURL?: string
  role?: AUTHOR_ROLE
  roles?: any[]
  userId?: string
  totalReads?: number
  professional?: number
  level?: AUTHOR_LEVEL
  active?: boolean
}