import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot } from "@angular/router"
import { SerieApiService } from "../services/api/modules/serie.api"
import { Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class SerieDetailGuard {
  constructor(
    private serieApiService: SerieApiService,
  ) {}
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.serieApiService.canAccessSerie(next.params.serieId)
  }
}