import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { AuthApiService } from '../services/api/modules/auth.api'
import { NetworkHandler } from '../services/network-handler.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authApiService: AuthApiService, private networkHandler: NetworkHandler) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.networkHandler.isNetworkConnected().then((connected) => {
        if (!connected) {
          resolve(true)
        } else {
          const redirect: boolean = route.data.redirect
          this.authApiService.checkJWT(redirect).then((resp) => {
            resolve(resp)
          })
        }
      })
    })
  }
}
