import { Injectable } from "@angular/core";
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto";
import { IAPProduct, StripeCheckoutSession, SubscriptionProduct } from "src/app/lib/interfaces/product/product.model";
import { env } from "src/environments/env";
import { ApiService } from "../api.service"
import { SK } from "../../util.service";

@Injectable()
export class ProductApiService extends ApiService {
  async getSubscriptionProducts(): Promise<SubscriptionProduct[]> {
    const countryId = this.globalService.getGlobalUser() ? this.globalService.getGlobalUser().country.id : ''
    const currentPlatform = this.util.getProductPlatform()
    const params: FindOptionsDto = {
      customParams: { currentPlatform, country: countryId },
    }

    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteGetSubscriptionProducts, await this.parseQueryParams(params))
      .subscribe(res => {
        if (!res) return resolve(null)
        for (const product of res) {
          if (!product.countryCode || product.countryCode === '') {
            product.countryCode = null
          }
        }
        resolve(res)
      },
      () => resolve(null)
      )
    })
  }

  async getIAPProducts(): Promise<IAPProduct[]> {
    const platform = this.util.getProductPlatform()

    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteGetIAPProducts, await this.parseQueryParams({ customParams: { platform }}))
      .subscribe(res => {
        resolve(res)
      },
      () => resolve(null)
      )
    })
  }

  async buyChapter(chapterId: number) {
    return new Promise(resolve => {
      this.postQuery(`${env.apiRouteBuyChapter}/${chapterId}`, {}).subscribe(res => resolve(res.success))
    })
  }

  public async checkVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getQuery(env.apiRouteCheckVersion).subscribe(res => resolve(res), err => reject(err))
    })
  }

  async createIAPCheckoutSession(product: IAPProduct, transactionId: string): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(env.apiRouteIAPCheckoutSession, { product, transactionId }).subscribe(resolve)
    })
  }

  async createStripeIAPCheckoutSession(productId: string): Promise<StripeCheckoutSession> {
    return new Promise(resolve => {
      this.postQuery(env.apiRouteStripeIAPCheckoutSession, { productId }).subscribe(resolve)
    })
  }

  async getAvailableChaptersToBuy(serieId: number): Promise<any[]> {
    const langId = await this.storage.get(SK.APP_LANG)

    return new Promise(async resolve => {
      this.getQueryWithParams(`purchase/availableChapters`, { serieId, langId }).subscribe(resolve)
    })
  }

  async buyVolume(volumeId: number, serieId: number): Promise<boolean> {
    return new Promise(resolve => {
      this.postQuery(`purchase/volume`, { volumeId, serieId }).subscribe(res => resolve(res.success))
    })
  }
}