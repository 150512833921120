import { Action, createReducer, on } from '@ngrx/store'
import * as ReaderActions from './reader.actions'
import { initialState, ReaderState } from './reader.state'

const readerReducer = createReducer(
  initialState,
  on(ReaderActions.setStoredPage, (state, action) => ({
    ...state,
    storedPage: action.storedPage
  })),
  on(ReaderActions.setQuality, (state, { quality }) => ({ ...state, quality })),
  on(ReaderActions.setReadingMode, (state, { readingMode }) => ({ ...state, readingMode })),
  on(ReaderActions.setShowLinks, (state, { showLinks }) => ({ ...state, showLinks })),
  on(ReaderActions.setCurrentPage, (state, { currentPage }) => ({ ...state, currentPage })),
  on(ReaderActions.setSlideHeight, (state, { slideHeight }) => ({ ...state, slideHeight })),
  on(ReaderActions.setOrientation, (state, { orientation }) => ({ ...state, orientation })),
  on(ReaderActions.setFooterHeight, (state, { footerHeight }) => ({ ...state, footerHeight })),
  on(ReaderActions.setShowSelectorModal, (state, { showSelectorModal }) => ({ ...state, showSelectorModal })),
  on(ReaderActions.setGoToPage, (state, { goToPage }) => ({ ...state, goToPage })),
  on(ReaderActions.setIsLastPage, (state, { isLastPage }) => ({ ...state, isLastPage })),
  on(ReaderActions.setIsZoomed, (state, { isZoomed }) => ({ ...state, isZoomed })),
  on(ReaderActions.setIsOffline, (state, { isOffline }) => ({ ...state, isOffline })),
  on(ReaderActions.setIsOnPosition, (state, { isOnPosition }) => ({ ...state, isOnPosition })),
  on(ReaderActions.setLang, (state, { lang }) => ({ ...state, lang })),
  on(ReaderActions.reset, () => initialState)
)

export function reducer(state: ReaderState | undefined, action: Action) {
  return readerReducer(state, action)
} // end_reducer
