import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { map, mergeMap } from "rxjs/operators"
import { UserApiService } from "src/app/lib/services/api/modules/user.api"
import * as UserChapterStatusActions from "./user-chapter-status.actions"

@Injectable()

export class UserChapterStatusEffects {
  constructor(
    private actions$: Actions,
    private userApiService: UserApiService
  ) {}

  fetchUserChapterStatus$ = createEffect((): any => 
    this.actions$.pipe(
      ofType(UserChapterStatusActions.loadUserChapterStatus),
      mergeMap(({ chapterID }) => this.userApiService.getUserChapterStatusRX(chapterID).pipe(
        map(userChapterStatus => UserChapterStatusActions.loadUserChapterStatusSuccess({ userChapterStatus })),
      ))
    )
  )
} // end_serieEffects 
