<ion-content class="purchaseSuccessContainer">
  <ion-title>
    <h1 style="display: flex; align-items: center; justify-content: center; gap: 20px; padding-top: 15px;">
      <ion-icon color="success" name="checkmark-circle-outline"></ion-icon>
      <b>{{'PURCHASE.SUCCESS_TITLE' | translate}}</b>
    </h1>
  </ion-title>
  <ion-grid style="margin: 0 15px;">
    <ion-row>
      <ion-col size="12">
        <ion-text class="successText">
          <h2>
            <b>{{ 'PURCHASE.RECEIVED' | translate }} {{this.iapProduct?.amount + this.iapProduct.extraAmount}} Koins</b>
          </h2>
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-text class="successText">
          <h4>
            {{'PURCHASE.SUCCESS_BODY1' | translate}}
          </h4>
          <h4>
            {{'PURCHASE.SUCCESS_BODY2' | translate}}
          </h4>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
