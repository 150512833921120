import { Action, createReducer, on } from '@ngrx/store'
import * as VolumeActions from './volume.actions'
import { initialState, VolumeState } from './volume.state'

const volumeReducer = createReducer(
  initialState,
  on(VolumeActions.loadVolume, (state) => ({ ...state, isLoading: true, isLoaded: false })),
  on(VolumeActions.loadVolumeSuccess, (state, { volume }) => ({ ...state, volume, isLoading: false, isLoaded: true })),
  on(VolumeActions.reset, () => initialState)
)

export function reducer(state: VolumeState | undefined, action: Action) {
  return volumeReducer(state, action)
} // end_reducer
