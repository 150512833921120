import { Injectable, NgZone } from '@angular/core'
import { SK, UtilService } from './util.service'
import { StorageService } from './storage.service'
import { ModalController, Platform } from '@ionic/angular'
import { NewsletterComponent } from 'src/app/components/newsletter/newsletter.component'
import { UserApiService } from './api/modules/user.api'

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(
    private storage: StorageService,
    public utilService: UtilService,
    private modalController: ModalController,
    private userApiService: UserApiService,
    private platform: Platform,
  ) {}

  public async requestOnInit() {
    const { valid: newsletterSub } = await this.userApiService.checkNewsletter()
    
    // await this.openModal() // remove before commit
    
    if (newsletterSub || newsletterSub === false) return;

    const reqDate = await this.storage.get(SK.NEWSLETTER_REQ_TIME) 
    let reqCount = await this.storage.get(SK.NEWSLETTER_REQ_COUNT) || 0

    if (await this.utilService.weekTrigger(reqDate,reqCount)) {
      reqCount += 1
      await this.storage.set(SK.NEWSLETTER_REQ_TIME, new Date().toISOString())
      await this.storage.set(SK.NEWSLETTER_REQ_COUNT, reqCount)
      await this.openModal()
    }
    
    if (reqCount >= 3) await this.userApiService.subscribeNewsletter(false)
  }

  async openModal() {
    let cssClass = 'modal-newsletter-desktop'
    let initialBreakpoint = 1
    if (this.platform.is('cordova') && !this.utilService.isTablet()) {
      cssClass = 'modal-newsletter-cordova'
      initialBreakpoint = 0.5
    }
    const modal = await this.modalController.create({
      component: NewsletterComponent,
      cssClass,
      backdropDismiss: true, // Dismiss when tapping the backdrop
      showBackdrop: true,
      initialBreakpoint,
      // breakpoints : [0.5, 0.5, 0.5],
    });
    return await modal.present();
  }
}
