import { Component } from '@angular/core'
import { UtilService } from 'src/app/lib/services/util.service';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
})

export default class AppHeader {
    constructor(
        public utilService: UtilService
    ) {}
} // end_AppHeader