import { Injectable } from '@angular/core'
import { env } from 'src/environments/env'
import { ApiService } from '../api.service'
import { String } from 'typescript-string-operations'
import {
  PublicUser,
  User,
  UserChapterStatus,
  UserPurchasedSubscription,
  UserSerieStatus,
} from 'src/app/lib/interfaces/user/user.model'
import { Genre } from 'src/app/lib/interfaces/genre/genre.model'
import { SK } from '../../util.service'
import { Lang } from 'src/app/lib/interfaces/lang/lang.model'
import { CustomSection } from 'src/app/lib/interfaces/serie/serie.model'
import { FindOptionsDto } from 'src/app/lib/interfaces/find-options.dto'
import { ChapterProductStatus } from 'src/app/lib/interfaces/chapter/chapter.model'
import { Observable, from } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { SERIE_FREQUENCY } from 'src/app/pages/settings/settings.page'
import { ChapterPage } from 'src/app/lib/interfaces/api.interface'

@Injectable()
export class UserApiService extends ApiService {
  checkUsername(username: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.getQuery(`${env.apiRouteCheckUsername}/${username}`).subscribe((res) => {
        resolve(res.exists)
      })
    })
  }

  validateUserAccount(userId: string): Promise<any> {
    return new Promise((resolve) => {
      this.updateQuery(`${String.format(env.apiRouteValidateUserAccount, userId)}`, { validate: true }).subscribe(
        (res) => {
          resolve(res)
        },
      )
    })
  }

  async getUser(userId: string): Promise<User> {
    return new Promise((resolve) => {
      this.getQuery(String.format(env.apiRouteUserDetail, userId)).subscribe(
        async (res) => {
          const userIdStored = await this.storage.get(SK.USER_ID)
          res.favouriteGenres = await this.getFavouriteGenres(res.id)
          res.favouriteLangs = await this.getFavouriteLangs(res.id)
          if (userIdStored === res.id) {
            this.globalService.setGlobalUser(res)
          }
          resolve(res)
        },
        () => resolve(null),
      )
    })
  }

  async getFavouriteGenres(userId: string): Promise<Genre[]> {
    const lang = await this.storage.get(SK.APP_LANG)

    return new Promise(async (resolve) => {
      this.getQueryWithParams(
        String.format(env.apiRouteGetFavouriteGenres, userId),
        await this.parseQueryParams({ lang }),
      ).subscribe((res) => {
        resolve(res.data)
      })
    })
  }

  async getFavouriteLangs(userId: string): Promise<Lang[]> {
    const lang = await this.storage.get(SK.APP_LANG)

    return new Promise(async (resolve) => {
      this.getQueryWithParams(
        String.format(env.apiRouteGetFavouriteLangs, userId),
        await this.parseQueryParams({ lang }),
      ).subscribe((res) => {
        resolve(res.data)
      })
    })
  }

  async getUserSerieStatus(serieId: number): Promise<UserSerieStatus> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      if (!userId) return resolve({})

      this.getQuery(`${String.format(env.apiRouteSerieStatus, userId)}/${serieId}`).subscribe(resolve)
    })
  }

  async getUserVolumeStatus(volumeId: number): Promise<UserChapterStatus[]> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      if (!userId) return resolve([])

      this.getQuery(`${String.format(env.apiRouteVolumeStatus, userId)}/${volumeId}`).subscribe(resolve)
    })
  }

  async getUserPurchasedSubscriptions(id: string): Promise<UserPurchasedSubscription[]> {
    return new Promise((resolve) => {
      this.getQuery(String.format(env.apiRouteGetUserPurchasedSubscriptions, id)).subscribe(
        (res) => {
          resolve(res)
        },
        () => resolve(null),
      )
    })
  }

  async getCustomSections(userId: string, other: boolean = false, offset: number): Promise<CustomSection[]> {
    return new Promise(async (resolve, reject) => {
      const ownUserId = await this.storage.get(SK.USER_ID)
      const lang = await this.storage.get(SK.APP_LANG)

      if (!userId) return resolve([])

      let query = { lang, offset }
      if (other) {
        query['customParams'] = {
          other,
          userId: ownUserId,
        }
      }

      this.getQueryWithParams(
        String.format(env.apiRouteGetCustomSections, userId),
        await this.parseQueryParams(query),
      ).subscribe(
        (res) => resolve(res.data),
        () => reject('Error'),
      )
    })
  }

  async getRecommendedUsers(findOptions: FindOptionsDto): Promise<PublicUser[]> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)

      this.getQueryWithParams(
        String.format(env.apiRouteGetRecommendedUsers, userId),
        await this.parseQueryParams(findOptions),
      ).subscribe((res) => resolve(res.data))
    })
  }

  async getTopLikesUsers(findOptions: FindOptionsDto): Promise<PublicUser[]> {
    return new Promise(async (resolve) => {
      this.getQueryWithParams(
        String.format(env.apiRouteGetTopLikesUsers),
        await this.parseQueryParams(findOptions),
      ).subscribe((res) => resolve(res.data))
    })
  }

  async getNewsByUser(userId: string, findOptions: FindOptionsDto): Promise<any[]> {
    return new Promise(async (resolve) => {
      this.getQueryWithParams(
        String.format(env.apiRouteGetNews, userId),
        await this.parseQueryParams(findOptions),
      ).subscribe((res) => resolve(res.data))
    })
  }

  async getCommentsByUser(authorId: string, findOptions: FindOptionsDto): Promise<any[]> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      this.getQueryWithParams(
        String.format(env.apiRouteGetCommentsByUser, userId, authorId),
        await this.parseQueryParams(findOptions),
      ).subscribe((res) => resolve(res.data))
    })
  }

  async getUserChapterProductStatus(chapterId: number): Promise<ChapterProductStatus> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      if (userId) {
        this.getQuery(`${String.format(env.apiRouteChapterProductStatus, userId)}/${chapterId}`).subscribe((res) =>
          resolve(res.data),
        )
      }
    })
  }

  async getUserChapterStatus(chapterId: number): Promise<UserChapterStatus> {
    const userId = await this.storage.get(SK.USER_ID)
    return new Promise((resolve) => {
      this.http
        .get<UserChapterStatus>(`${env.apiUrl}/${String.Format(env.apiRouteChapterStatus, userId)}/${chapterId}`)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  async setUserCurrentSecond(chapterId: number, currentSecond: number, lang: number): Promise<any> {
    const userId = await this.storage.get(SK.USER_ID)

    return new Promise((resolve) => {
      this.postQuery(`${env.apiUrl}/userStatus/${userId}/currentSecond?lang=${lang}`, {
        chapterId,
        currentSecond,
      }).subscribe(() => resolve(true))
    })
  }

  getUserChapterStatusRX(chapterId: number): Observable<UserChapterStatus> {
    return from(this.storage.get(SK.USER_ID)).pipe(
      switchMap((userId) => {
        return this.http.get<UserChapterStatus>(
          `${env.apiUrl}/${String.Format(env.apiRouteChapterStatus, userId)}/${chapterId}`,
        )
      }),
    )
  }

  async setCurrentPage(currentPage: number, chapterId: number, lang: number) {
    const userId = await this.storage.get(SK.USER_ID)

    return new Promise((resolve) => {
      this.postQuery(`${String.format(env.apiRouteCurrentPage, userId)}?lang=${lang}`, {
        chapterId,
        currentPage,
      }).subscribe(() => resolve(true))
    })
  }

  async setCurrentPercentage(percentage: number, chapterId: number, lang: number) {
    const userId = await this.storage.get(SK.USER_ID)

    return new Promise((resolve) => {
      this.postQuery(`${String.format(env.apiRouteCurrentPercentage, userId)}?lang=${lang}`, {
        chapterId,
        percentage,
      }).subscribe(() => resolve(true))
    })
  }

  async advancedSearchUsers(findOptions: FindOptionsDto): Promise<PublicUser[]> {
    return new Promise(async (resolve) => {
      this.getQueryWithParams(env.apiRouteAdvancedUserSearch, await this.parseQueryParams(findOptions)).subscribe(
        (res) => resolve(res.data),
      )
    })
  }

  public setFirstTimeLogged(firstTimeLogged: boolean): Promise<any> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      if (!userId) return resolve(null)

      this.updateQuery(String.format(env.apiRouteSetFirstTimeLogged, userId), { firstTimeLogged }).subscribe((res) => {
        resolve(res)
      })
    })
  }

  public async contact(topic: string, subject: string, message: string) {
    const userId = await this.storage.get(SK.USER_ID)
    return new Promise((resolve, reject) => {
      this.postQuery(env.apiRouteContact, {
        topic,
        subject,
        message,
        userId,
      }).subscribe(
        () => {
          resolve(true)
        },
        (err) => reject(err),
      )
    })
  }

  async followUnfollowSerie(serieId: number, follow: boolean): Promise<boolean> {
    const userId = await this.storage.get(SK.USER_ID)

    const followUri = String.format(env.apiRouteFollowSerie, userId, serieId)
    const unfollowUri = String.format(env.apiRouteUnfollowSerie, userId, serieId)
    return new Promise((resolve) => {
      this.postQuery(follow ? followUri : unfollowUri, {}).subscribe(() => {
        resolve(true)
      })
    })
  }

  likeUnlikeChapter(chapterId: number, like: boolean): Promise<any> {
    const likeUri = String.format(env.apiRouteLikeChapter, chapterId)
    const unlikeUri = String.format(env.apiRouteUnlikeChapter, chapterId)
    return new Promise((resolve) => {
      this.postQuery(like ? likeUri : unlikeUri, {}).subscribe((res) => resolve(res.data))
    })
  }

  hideGenres(genres: Genre[]): Promise<any> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      if (!userId) return resolve(null)

      this.postQuery(env.apiRouteHideGenres, { genres }).subscribe(resolve)
    })
  }

  updateLibraryPrivacy(privated: boolean): Promise<any> {
    return new Promise((resolve) => {
      this.updateQuery(env.apiRouteChangeLibraryPrivacy, { privated }).subscribe(resolve)
    })
  }

  async changeLanguage(lang: number) {
    const userId = await this.storage.get(SK.USER_ID)
    return new Promise(async (resolve) => {
      this.http
        .put(`${env.apiUrl}/${String.Format(env.apiRouteChangeLanguage, userId)}`, { lang })
        .subscribe(async (res) => {
          resolve(res)
        })
    })
  }

  updateAllowDirectMessages(allowed: boolean): Promise<any> {
    return new Promise((resolve) => {
      this.updateQuery(env.apiRouteChangeAllowDirectMessages, { allowed }).subscribe(resolve)
    })
  }

  updateSeriesNewsFrequency(frequency: SERIE_FREQUENCY): Promise<any> {
    return new Promise((resolve) => {
      this.updateQuery(env.apiRouteChangeSeriesNewsFrequency, { frequency }).subscribe(resolve)
    })
  }

  public updatePushNotif(key: string, value: boolean): Promise<any> {
    return new Promise((resolve) => {
      this.updateQuery(env.apiRouteChangePushNotif, { key, value }).subscribe(resolve)
    })
  }

  markGenresAsFavourite(genres: Genre[]): Promise<any> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      if (!userId) return resolve(null)

      this.postQuery(env.apiRouteMarkGenresAsFavourite, { genres }).subscribe(resolve)
    })
  }

  async markLangsAsFavourite(langs: Lang[]): Promise<any> {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      if (!userId) return resolve(null)

      this.postQuery(env.apiRouteMarkLangsAsFavourite, { langs }).subscribe(resolve)
    })
  }

  updateUserProfile(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      this.updateQuery(env.apiRouteUpdateUserProfile, { user }).subscribe(
        async (res) => resolve(res),
        async (err) => reject(err),
      )
    })
  }

  uploadAvatar(image: FormData) {
    return new Promise((resolve) => {
      this.postQuery(env.apiRouteUploadAvatar, image).subscribe(resolve)
    })
  }

  setAnnotations(serieId: number, annotations: string, volumeId: number) {
    return new Promise((resolve) => {
      this.postQuery(String.format(env.apiRouteAnnotations, serieId), { annotations, volumeId }).subscribe(resolve)
    })
  }

  setPageAsFavorite(chapterId: number, pageNumber: number, value: boolean, isPrivate: boolean = false) {
    return new Promise<boolean>((resolve) => {
      this.postQuery(env.apiRouteSetPageAsFavorite, {
        chapterId,
        pageNumber,
        value,
        isPrivate,
      }).subscribe(resolve)
    })
  }

  setCurrentChapter(serieId: number, volumeId: number, chapterId: number, force: boolean = false) {
    return new Promise((resolve) => {
      this.postQuery(env.apiRouteCurrentChapter, {
        serieId,
        volumeId,
        chapterId,
        force,
      }).subscribe(resolve)
    })
  }

  async createCustomSection(name: string, query: string): Promise<any> {
    return new Promise((resolve) => {
      this.postQuery(env.apiRouteCreateCustomSection, { name, query }).subscribe(resolve)
    })
  }

  async deleteCustomSection(sectionId: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.removeQuery(`${env.apiRouteDeleteCustomSection}/${sectionId}`).subscribe(resolve)
    })
  }

  async getFavoritePages(userId: string, findOptions: FindOptionsDto): Promise<ChapterPage[]> {
    return new Promise(async (resolve) => {
      if (!userId) return resolve([])

      this.getQueryWithParams(
        String.format(env.apiRouteFavoritePages, userId),
        await this.parseQueryParams(findOptions),
      ).subscribe(resolve)
    })
  }

  async getFavoritePagesBySerie(userId: string, serieId: number, findOptions: FindOptionsDto): Promise<ChapterPage[]> {
    return new Promise(async (resolve) => {
      if (!userId) return resolve([])

      this.getQueryWithParams(
        String.format(env.apiRouteFavoritePagesBySerie, userId, serieId),
        await this.parseQueryParams(findOptions),
      ).subscribe(resolve)
    })
  }

  async getPublicUser(userId: string): Promise<PublicUser> {
    return new Promise((resolve) => {
      this.getQuery(String.format(env.apiRoutePublicUser, userId)).subscribe(async (res) => {
        res.favoriteGenres = await this.getFavouriteGenres(res.id)
        res.favoriteLangs = await this.getFavouriteLangs(res.id)

        resolve(res)
      })
    })
  }

  async duplicateCustomSection(sectionId: number, name: string): Promise<any> {
    return new Promise((resolve) => {
      this.postQuery(String.format(env.apiRouteDuplicateCustomSection, sectionId), { name }).subscribe(resolve)
    })
  }
  public async setOfflineReadedPages(chapterId: number, lang: number) {
    return new Promise((resolve, reject) => {
      this.postQuery(env.apiRouteOfflineReadedPages, { chapterId, lang }).subscribe(resolve, reject)
    })
  }

  async getCustomSection(sectionId: number, findOptions: FindOptionsDto): Promise<CustomSection> {
    return new Promise(async (resolve) => {
      this.getQueryWithParams(
        String.format(env.apiRouteGetCustomSection, sectionId),
        await this.parseQueryParams(findOptions),
      ).subscribe(resolve)
    })
  }

  public async addOneSignalIdToSession() {
    const sessionId = await this.storage.get(SK.SESSION_ID)
    const oneSignalId = await this.storage.get(SK.ONE_SIGNAL_ID)

    if (!sessionId || !oneSignalId) return

    return new Promise((resolve) => {
      this.postQuery(env.apiRouteAddOneSignalIdToSession, { sessionId, oneSignalId }).subscribe(resolve)
    })
  }

  public async checkBirthDate(serieId: number): Promise<{ valid: boolean; message: string }> {
    return new Promise((resolve) => {
      this.getQueryWithParams(env.apiRouteUserCheckBirth, { serieId }).subscribe(resolve)
    })
  }

  public async submitBirthDate(birthDate: Date): Promise<any> {
    return new Promise((resolve) => {
      this.postQuery(env.apiRouteUserCheckBirth, { birthDate }).subscribe(resolve)
    })
  }

  public async checkNewsletter(): Promise<{ valid: boolean }> {
    return new Promise((resolve) => {
      this.getQuery(env.apiRouteUserCheckNewsletter).subscribe(resolve)
    })
  }

  public async subscribeNewsletter(subNewsletter: boolean): Promise<{ valid: boolean }> {
    return new Promise((resolve) => {
      this.postQuery(env.apiRouteUserSubscribeNewsletter, { subNewsletter }).subscribe(resolve)
    })
  }

  public async updateFamilyMode(subFamilyMode: boolean): Promise<{ valid: boolean }> {
    return new Promise((resolve) => {
      this.postQuery(env.apiRouteUserUpdateFamilyMode, { subFamilyMode }).subscribe(resolve)
    })
  }

  public async checkFamilyMode(): Promise<{ valid: boolean }> {
    return new Promise((resolve) => {
      this.getQuery(env.apiRouteUserCheckFamilyMode).subscribe(resolve)
    })
  }
}
