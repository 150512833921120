import { NgModule } from '@angular/core'
import { ImagePreloadDirective } from './image-preload.directive'
import { HideHeaderDirective } from './hide-header.directive'
import { ExpandableHeaderDirective } from './expandable-header.directive'
import { ParallaxHeaderDirective } from './parallax-header.directive'
import { RouteTransformDirective } from './route-transform.directive'
import { AfterIfDirective } from './after-if.directive'
import { EpubHideDirective } from './epub-hide.directive'

@NgModule({
  declarations: [
    ImagePreloadDirective,
    HideHeaderDirective,
    ExpandableHeaderDirective,
    ParallaxHeaderDirective,
    RouteTransformDirective,
    AfterIfDirective,
    EpubHideDirective,
  ],
  exports: [
    ImagePreloadDirective,
    HideHeaderDirective,
    ExpandableHeaderDirective,
    ParallaxHeaderDirective,
    RouteTransformDirective,
    AfterIfDirective,
    EpubHideDirective,
  ],
})
export class DirectivesModule {}
