import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto";
import { env } from 'src/environments/env'
import { AlertService } from "../alert.service";
import { GlobalService } from "../global.service";
import { NetworkHandler } from "../network-handler.service";
import { StorageService } from "../storage.service";
import { SK, UtilService } from "../util.service";

export enum OrderBy {
  POPULAR = 'popular',
  BEST_RATED = 'bestRated',
  LAST_RELEASES = 'lastReleases',
  TITLE = 'title',
  SEARCHED = 'searched',
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public url = env.apiUrl

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public globalService: GlobalService,
    public util: UtilService,
    public networkHandler: NetworkHandler,
    public navCtrl: NavController,
    public alertService: AlertService,
  ) {}

  getQuery(query: string) {
    return this.http.get<any>(new URL(query, this.url).href)
  }

  getBlob(query: string) {
    return this.http.get(new URL(query, this.url).href, { responseType: 'blob'})
  }

  getQueryWithParams(query: string, params: any) {
    return this.http.get<any>(new URL(query, this.url).href, { params: params })
  }

  updateQuery(query: string, body: any) {
    return this.http.put<any>(new URL(query, this.url).href, body)
  }

  postQuery(query: string, body: any) {
    return this.http.post<any>(new URL(query, this.url).href, body)
  }

  removeQuery(query: string) {
    return this.http.delete<any>(new URL(query, this.url).href)
  }

  async parseQueryParams(findOptionsDto: FindOptionsDto): Promise<any> {
    const appLang = await this.storage.get(SK.APP_LANG)
    const params = {
      lang: findOptionsDto?.lang ?? appLang,
    }
    if (findOptionsDto?.limit) params['limit'] = findOptionsDto.limit
    if (findOptionsDto?.offset) params['offset'] = findOptionsDto.offset
    if (findOptionsDto?.orderBy) params['orderBy'] = findOptionsDto.orderBy

    for (const key in findOptionsDto.customParams) {
      if (findOptionsDto.customParams[key]) params[key] = findOptionsDto.customParams[key]
    }

    return params
  }

}