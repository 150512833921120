import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { map, mergeMap } from "rxjs/operators"
import { SerieApiService } from "src/app/lib/services/api/modules/serie.api"
import * as SerieActions from "./serie.actions"

@Injectable()

export class SerieEffects {
  constructor(
    private actions$: Actions,
    private serieApiService: SerieApiService
  ) {}

  fetchSerie$ = createEffect((): any => 
    this.actions$.pipe(
      ofType(SerieActions.loadSerie),
      mergeMap(({ serieID }) => this.serieApiService.getSerieDetailRX(serieID).pipe(
        map(serie => SerieActions.loadSerieSuccess({ serie })),
      ))
    )
  )
} // end_serieEffects 
