import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { UtilService } from '../lib/services/util.service'
import { from, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'

@Injectable()
export default class VersionInterceptor implements HttpInterceptor {
  platform: string = this.utilService.getPlatform()
  constructor(
      private appVersion: AppVersion,
      private utilService: UtilService,
      ) {
  } // end_constructor

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getVersion()).pipe(
      switchMap((version) => {
        
        let newReq = req.clone({
          headers: req.headers.append('version', version).append('platform', this.platform)
        })

        return next.handle(newReq)
      }),
      catchError((err) => {
        if (err === 'cordova_not_available') return next.handle(req)
        else return throwError(err)
      }),
    )
  }

  async getVersion(): Promise<string> {
    return new Promise(async resolve => {
      if (this.platform === 'web') resolve('0')
      else resolve(await (await this.appVersion.getVersionCode()).toString())
    })
  }
}
