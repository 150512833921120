import { Injectable } from "@angular/core"
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto"
import { Lang } from "src/app/lib/interfaces/lang/lang.model"
import { env } from "src/environments/env"
import { SK } from "../../util.service"
import { ApiService } from "../api.service"
import { String } from 'typescript-string-operations'

@Injectable()
export class LangApiService extends ApiService {
  async getLangs(): Promise<Lang[]> {
    const lang = await this.storage.get(SK.APP_LANG)
    const userId = await this.storage.get(SK.USER_ID)

    return new Promise(async resolve => {
      const params: FindOptionsDto = {
        lang,
        customParams: {
          userId
        }
      }

      this.getQueryWithParams(env.apiRouteLangs, await this.parseQueryParams(params))
      .subscribe(res => resolve(res.data))
    })
  }

  async getLangsByChapter(chapterId: number): Promise<{ langs: any[], defaultLang: any}> {
    const lang = await this.storage.get(SK.APP_LANG)

    return new Promise(async resolve => {
      const params: FindOptionsDto = {
        lang,
      }

      this.getQueryWithParams(String.format(env.apiRouteChapterLangs, chapterId), await this.parseQueryParams(params))
      .subscribe(res => resolve(res.data))
    })

  }
}