import { Injectable } from "@angular/core"
import { ApiService } from "../api.service"
import { String } from "typescript-string-operations"
import { env } from "src/environments/env"

@Injectable()
export class RemoteSettingsApiService extends ApiService {
  async getRemoteSetting(key: string): Promise<string> {
    return new Promise(resolve => {
      this.getQuery(String.format(env.apiRouteGetRemoteSetting, key)).subscribe(resolve)
    })
  }
}