import { Component, ViewChild } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { UserApiService } from 'src/app/lib/services/api/modules/user.api'

@Component({
  selector: 'app-select-family-mode',
  templateUrl: './select-family-mode.component.html',
  styleUrls: ['./select-family-mode.component.scss'],
})
export class SelectFamilyModeComponent {
  constructor(private userApiService: UserApiService, private popoverController: PopoverController) {}

  cancel() {
    return this.popoverController.dismiss(false)
  }

  confirm() {
    this.userApiService.updateFamilyMode(false).then((res) => {
      if (!res.valid) {
        location.reload()
        return this.popoverController.dismiss(true)
      }
    })
  }
}
