<ion-app (click)="onScreenClick()">
  <div *ngIf="this.localizationService.langReady">
    <app-suggest-download></app-suggest-download>
    <!--<ion-router-outlet style="max-width: 1400px; margin: 0 auto;"></ion-router-outlet>-->
    <ion-router-outlet
      [ngStyle]="{'top': this.global.getIsSuggestedOpen() ? '80px' : '0'}" 
      [className]="this.util.isDesktop() ? 'ion-router-desktop' : ''"
    >
    </ion-router-outlet>
  </div>
</ion-app>
