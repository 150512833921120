import { Action, createReducer, on } from '@ngrx/store'
import * as UserChapterStatusActions from './user-chapter-status.actions'
import { initialState, UserChapterStatusState } from './user-chapter-status.state'

const userChapterStatusReducer = createReducer(
  initialState,
  on(UserChapterStatusActions.loadUserChapterStatus, (state, { chapterID }) => 
    ({ ...state, chapterID, isLoading: true, isLoaded: false })),
  on(UserChapterStatusActions.loadUserChapterStatusSuccess, (state, { userChapterStatus }) => 
    ({ ...state, ...userChapterStatus, isLoading: false, isLoaded: true })),
  on(UserChapterStatusActions.setCurrentChapter, (state, { isCurrentChapter }) =>
    ({ ...state, isCurrentChapter })),
  on(UserChapterStatusActions.reset, () => initialState)
)

export function reducer(state: UserChapterStatusState | undefined, action: Action) {
  return userChapterStatusReducer(state, action)
}