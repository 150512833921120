import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ReaderState } from './reader.state'

export const selectReader = createFeatureSelector<ReaderState>('reader')

export const selectReaderReadingMode = createSelector(
  selectReader,
  (state: ReaderState) => state.readingMode
)

export const selectReaderQuality = createSelector(
  selectReader,
  (state: ReaderState) => state.quality
)

export const selectShowLinks = createSelector(
  selectReader,
  (state: ReaderState) => state.showLinks
)

export const selectCurrentPage = createSelector(
  selectReader,
  (state: ReaderState) => state.currentPage
)

export const selectSlideHeight = createSelector(
  selectReader,
  (state: ReaderState) => state.slideHeight
)

export const selectOrientation = createSelector(
  selectReader,
  (state: ReaderState) => state.orientation
)

export const selectFooterHeight = createSelector(
  selectReader,
  (state: ReaderState) => state.footerHeight
)

export const selectShowSelectorModal = createSelector(
  selectReader,
  (state: ReaderState) => state.showSelectorModal
)

export const selectGoToPage = createSelector(
  selectReader,
  (state: ReaderState) => state.goToPage
)

export const selectIsLastPage = createSelector(
  selectReader,
  (state: ReaderState) => state.isLastPage
)

export const selectIsZoomed = createSelector(
  selectReader,
  (state: ReaderState) => state.isZoomed
)

export const selectIsOffline = createSelector(
  selectReader,
  (state: ReaderState) => state.isOffline
)

export const selectIsOnPosition = createSelector(
  selectReader,
  (state: ReaderState) => state.isOnPosition
)

export const selectLang = createSelector(
  selectReader,
  (state: ReaderState) => state.lang
)