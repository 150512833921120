import { createFeatureSelector, createSelector } from '@ngrx/store'
import { SerieState } from './serie.state'

export const serieSelector = createFeatureSelector<SerieState>('serie')

export const selectSerie = createSelector(
  serieSelector,
  (state: SerieState) => state.serie
)

export const selectSerieReadingMode = createSelector(
  serieSelector,
  (state: SerieState) => state.serie?.readingMode
)

export const selectSerieLoaded = createSelector(
  serieSelector,
  (state: SerieState) => state.isLoaded
)


