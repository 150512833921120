import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ChapterState } from './chapter.state'

export const chapterSelector = createFeatureSelector<ChapterState>('volume')

export const selectChapter = createSelector(
  chapterSelector,
  (state: ChapterState) => state.chapter
)

export const selectChapterLoaded = createSelector(
  chapterSelector,
  (state: ChapterState) => state.isLoaded
)



