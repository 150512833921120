import { createAction, props } from "@ngrx/store"

export const setStoredPage = createAction(
  "[Reader] Set Stored Page",
  props<{ storedPage: number }>()
)

export const setQuality = createAction(
  "[Reader] Set Quality",
  props<{ quality: string }>()
)

export const setReadingMode = createAction(
  "[Reader] Set Reading Mode",
  props<{ readingMode: string }>()
)

export const setShowLinks = createAction(
  "[Reader] Set Show Links",
  props<{ showLinks: boolean }>()
)

export const setCurrentPage = createAction(
  "[Reader] Set Current Page",
  props<{ currentPage: any }>()
)

export const setSlideHeight = createAction(
  "[Reader] Set Slide Height",
  props<{ slideHeight: number }>()
)

export const setOrientation = createAction(
  "[Reader] Set Orientation",
  props<{ orientation: string }>()
)

export const setFooterHeight = createAction(
  "[Reader] Set Footer Height",
  props<{ footerHeight: number }>()
)

export const setShowSelectorModal = createAction(
  "[Reader] Set Show Selector Modal",
  props<{ showSelectorModal: boolean }>()
)

export const setGoToPage = createAction(
  "[Reader] Set Go To Page",
  props<{ goToPage: number }>()
)

export const setIsLastPage = createAction(
  "[Reader] Set Is Last Page",
  props<{ isLastPage: boolean }>()
)

export const setIsZoomed = createAction(
  "[Reader] Set Is Zoomed",
  props<{ isZoomed: boolean }>()
)

export const setIsOffline = createAction(
  "[Reader] Set Is Offline",
  props<{ isOffline: boolean }>()
)

export const setIsOnPosition = createAction(
  "[Reader] Set Is On Position",
  props<{ isOnPosition: boolean }>()
)

export const setLang = createAction(
  "[Reader] Set Lang",
  props<{ lang: number }>()
)

export const reset = createAction("[Reader] Reset")
