import { Injectable } from "@angular/core"
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto"
import { Genre } from "src/app/lib/interfaces/genre/genre.model"
import { env } from "src/environments/env"
import { SK } from "../../util.service"
import { ApiService } from "../api.service"

@Injectable()
export class GenreApiService extends ApiService {
  async getGenres(showHidden = false): Promise<Genre[]> {
    const lang = await this.storage.get(SK.APP_LANG)
    const userId = await this.storage.get(SK.USER_ID)
    let params: FindOptionsDto = { lang, customParams: { showHidden } }

    if (userId) params.customParams['userId'] = userId

    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteGenres, await this.parseQueryParams(params))
      .subscribe(res => {
        resolve(res.data)
      })
    })
  }
}