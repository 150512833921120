import { createAction, props } from "@ngrx/store"

export const loadUserChapterStatus = createAction(
  "[UserChapterStatus] Fetch UserChapterStatus",
  props<{ chapterID: number }>()
)

export const loadUserChapterStatusSuccess = createAction(
  "[UserChapterStatus] Fetch UserChapterStatus Success",
  props<{ userChapterStatus: any }>()
)

export const setCurrentChapter = createAction(
  "[UserChapterStatus] Set Current Chapter",
  props<{ isCurrentChapter: boolean }>()
)

export const reset = createAction(
  "[UserChapterStatus] Rest"
)