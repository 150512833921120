import { Injectable, EventEmitter, Output } from '@angular/core'
import { UtilService } from './util.service'
import * as currencyFormatter from 'currency-formatter'
import { env } from '../../../environments/env'
import { LoadingService } from './loading.service'
import { loadStripe } from '@stripe/stripe-js/pure'
import { ToastService } from './toast.service'
import { ModalController, Platform } from '@ionic/angular'
import { Country } from '../interfaces/country/country.model'
import { ProductApiService } from './api/modules/product.api'
import { IAPProduct as CustomIAPProduct, PRODUCT_PLATFORM, SubscriptionProduct } from '../interfaces/product/product.model'
import { UserPurchasedSubscription } from '../interfaces/user/user.model'
import { Purchases, LOG_LEVEL, PRODUCT_CATEGORY, PurchasesStoreProduct } from '@revenuecat/purchases-capacitor'
import { State } from '../store'
import { Store } from '@ngrx/store'
import * as AuthActions from '../store/auth/auth.actions'
import { Router } from '@angular/router'
import { PurchaseSuccessComponent } from '../../components/purchase-success/purchase-success.component'

export enum PURCHASE_STATE {
  INITIATED = 'initiated',
  REQUESTED = 'requested',
  APPROVED = 'approved',
  VERIFIED = 'verified',
  FINISHED = 'finished',
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  shopOpened: boolean = false
  subscriptionOpened: boolean = false
  subscriptionProducts: SubscriptionProduct[] = []
  subscriptionProduct: SubscriptionProduct

  userPurchasedSubscriptions: UserPurchasedSubscription[] = []
  userPurchasedSubscription: UserPurchasedSubscription

  iapProducts: CustomIAPProduct[] = []

  countries: Country[]

  payingProcessInitialized: boolean = false
  currentPurchaseState: PURCHASE_STATE = PURCHASE_STATE.FINISHED

  productsLoaded: boolean = false
  private revenueProducts: PurchasesStoreProduct[] = []

  @Output() onProductsLoaded: EventEmitter<any> = new EventEmitter()
  constructor(
    private util: UtilService,
    public loadingService: LoadingService,
    private toastService: ToastService,
    private productApiService: ProductApiService,
    public platform: Platform,
    private store: Store<State>,
    private router: Router,
    private modalController: ModalController
  ) {
    this.productsLoaded = false
  }

  public async init(productPlatform: PRODUCT_PLATFORM, userId: string): Promise<void> {
    await this.configure(productPlatform, userId)
    await this.loadIAPProducts(productPlatform)
  }

  public async configure(productPlatform: PRODUCT_PLATFORM, userId: string): Promise<void> {
    try {
      if (productPlatform === PRODUCT_PLATFORM.STRIPE) return

      let apiKey: string
      const debug = env.production ? LOG_LEVEL.ERROR : LOG_LEVEL.DEBUG

      await Purchases.setLogLevel({ level: debug })

      if (this.util.isAndroid()) apiKey = env.revenueCatAndroidApiKey
      else if (this.util.isiOS()) apiKey = env.revenueCatAppleApiKey
      
      await Purchases.configure({
          apiKey,
          appUserID: userId,
      })
    } catch {
      console.warn("error")
    }

  }

  async loadIAPProducts(productPlatform: PRODUCT_PLATFORM) {
    const iapProds = await this.productApiService.getIAPProducts()

    if (!iapProds) return

    this.iapProducts = iapProds.sort((a, b) => a.order - b.order)

    if (productPlatform !== PRODUCT_PLATFORM.STRIPE) {
      const { products } = await Purchases.getProducts({ productIdentifiers: this.iapProducts.map((x) => x.id), type: PRODUCT_CATEGORY.NON_SUBSCRIPTION })
      
      this.revenueProducts = products
  
      products.forEach(p => {
        const iap = this.iapProducts.find((x) => x.id == p.identifier)
        if (iap) {
          iap.price = p.price
          iap.currency = p.currencyCode
        }
      })
    }

    this.store.dispatch(AuthActions.setCanEnterShop({ canEnterShop: true }))
  }

  closeLoading() {
    this.payingProcessInitialized = false
    this.loadingService.hide()
  } // end_closeLoading

  getFormattedPrice(price: string, currency: string) {
    try {
      return currencyFormatter.format(price, { code: currency.toUpperCase() })
    } catch (err) {
      return null
    }
  }

  public async purchaseDesktop(id: string): Promise<void> {
    const response = await this.productApiService.createStripeIAPCheckoutSession(id)
    const stripe = await loadStripe(env.stripeId)
    stripe
      .redirectToCheckout({
        sessionId: response.sessionId,
      })
      .then(async (result) => {
        this.toastService.presentToast('Error: ' + result.error.message)
      })
  }

  public async purchaseMobile(id: string): Promise<boolean> {
    const product: any = this.revenueProducts.find((x) => x.identifier === id)
    const purchase = await Purchases.purchaseStoreProduct({ product })

    return purchase.customerInfo.entitlements.active['standard'] !== undefined
  }
}
