import { createAction, props } from "@ngrx/store"

export const loadVolume = createAction(
  "[Volume] Load Volume",
  props<{ volumeID: number }>()
)

export const loadVolumeSuccess = createAction(
  "[Volume] Load Volume Success",
  props<{ volume: any }>()
)

export const reset = createAction("[Volume] Reset")