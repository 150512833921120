import { createSelector } from '@ngrx/store'
import { UserChapterStatusState } from './user-chapter-status.state'
import { State } from '../core.state'

export const selectUCS = (state: State) => state.userChapterStatus

export const selectUserCurrentPage = createSelector(
  selectUCS,
  (state: UserChapterStatusState) => state.currentPage
)

export const selectUserCurrentSecond = createSelector(
  selectUCS,
  (state: UserChapterStatusState) => state.currentSecond
)

export const selectUserCurrentPercentage = createSelector(
  selectUCS,
  (state: UserChapterStatusState) => state.currentPercentage
)

export const selectUserLike = createSelector(
  selectUCS,
  (state: UserChapterStatusState) => state.like
)