import { Injectable } from "@angular/core"
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto"
import { OfflineSerie, SERIE_FORMAT, Serie, SerieDetail } from "src/app/lib/interfaces/serie/serie.model"
import { env } from "src/environments/env"
import { SK } from "../../util.service"
import { ApiService } from "../api.service"
import { String } from "typescript-string-operations"
import { Observable, from } from "rxjs"
import { switchMap } from "rxjs/operators"

@Injectable()
export class SerieApiService extends ApiService {
  public getLastVolumeFromSerie(serieId: number): Promise<{ id: number }> {
    return new Promise(async (resolve, reject) => {
      const langId = await this.storage.get(SK.APP_LANG)

      this.getQuery(`serie/lastVolume/${serieId}/${langId}`).subscribe(
        res => resolve(res),
        err => reject(err)
      )
    })
  }

  getPopularSeries(findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async (resolve, reject) => {
      this.getQueryWithParams(env.apiRouteSerieMostPopular, await this.parseQueryParams(findOptionsDto)).subscribe(res => {
        resolve(res.data)
      },
      (err) => {
        reject(err)
      }
      )
    })
  }

  getLastReleasesSeries(findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async (resolve, reject) => {
      this.getQueryWithParams(env.apiRouteLastReleases, await this.parseQueryParams(findOptionsDto)).subscribe(res => {
        resolve(res.data)
      },
      (err) => {
        reject(err)
      })
    })
  }

  async getSerieDetail(serieId: number): Promise<SerieDetail> {
    const userId = await this.storage.get(SK.USER_ID)
    const lang = await this.storage.get(SK.APP_LANG)
    const findOptionsDto: FindOptionsDto = { lang, customParams: { userId } }

    return new Promise(async (resolve, reject) => {
      this.getQueryWithParams(`${env.apiRouteSerie}/${serieId}`, await this.parseQueryParams(findOptionsDto)).subscribe(
        res => resolve(res),
        err => reject(err)
      )
    })
  }

  async getFollowingSeries(userId: string, findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async (resolve, reject) => {
      if (!userId) return resolve([])

      this.getQueryWithParams(String.format(env.apiRouteFollowingSeries,userId), await this.parseQueryParams(findOptionsDto))
      .subscribe(res => {
        resolve(res.data)
      },
      err => reject(err)
      )
    })
  }

  async getRecomendedSeries(userId: string, findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async (resolve, reject) => {
      if (!userId) return resolve([])

      this.getQueryWithParams(String.format(env.apiRouteRecomendedSeries,userId), await this.parseQueryParams(findOptionsDto))
      .subscribe(res => {
        resolve(res.data)
      },
      err => reject(err)
      )
    })
  }

  public async getNextReleases(findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteNextReleases, await this.parseQueryParams(findOptionsDto)).subscribe(res => {
        resolve(res.data)
      })
    })
  }

  async getSeriesByGenres(genreId: number, findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(env.apiRouteSerieByGenre, genreId), await this.parseQueryParams(findOptionsDto))
      .subscribe(res => {
        resolve(res.data)
      })
    })
  }

  async getRecentlySearchedSeries(findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteRecentlySearchedSeries, await this.parseQueryParams(findOptionsDto))
      .subscribe(res => resolve(res.data))
    })
  }

  async advancedSearchSeries(findOptionsDto: FindOptionsDto): Promise<SerieDetail[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteAdvancedSerieSearch, await this.parseQueryParams(findOptionsDto))
      .subscribe(res => resolve(res.data))
    })
  }

  async getAlltimeSearchedSeries(findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async (resolve) => {
      this.getQueryWithParams(env.apiRouteAlltimeSearchedSeries, await this.parseQueryParams(findOptionsDto))
      .subscribe(res => resolve(res.data))
    })
  }

  getSerieDetailRX(serieId: number): Observable<SerieDetail> {
    return from(Promise.all([
      this.storage.get(SK.APP_LANG),
      this.storage.get(SK.USER_ID),
    ])).pipe(
      switchMap(([lang, userId]) => {
        return this.getQuery(
            `${env.apiUrl}/${env.apiRouteSerie
            }/${serieId}?lang=${lang}&userId=${userId}&platform=${this.util.getPlatform()}`,
          )
      }))
  }

  async getSeriesByEditorial(editorialId: number, findOptions: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(`${env.apiRouteSerieByEditorial}/${editorialId}`, await this.parseQueryParams(findOptions))
      .subscribe(res => resolve(res.data))
    })
  }

  async getLastReads(userId: string, findOptions: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      if (!userId) return resolve([])

      this.getQueryWithParams(String.format(env.apiRouteLastReads, userId), await this.parseQueryParams(findOptions))
      .subscribe(resolve)
    })
  }

  async getSeriesWithAnnotations(userId: string, findOptions: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      if (!userId) return resolve([])

      this.getQueryWithParams(String.format(env.apiRouteSeriesWithAnnotations, userId), await this.parseQueryParams(findOptions))
      .subscribe(resolve)
    })
  }

  async rateSerie(serieId: number, rating: number): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteRateSerie, serieId), { rating }).subscribe(resolve)
    })
  }

  async shareSerie(serieId: string): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteShareSerie, serieId), { share: true }).subscribe(resolve)
    })
  }

  async getOfflineSerie(serieId: number): Promise<OfflineSerie> {
    const params: FindOptionsDto = { lang: await this.storage.get(SK.APP_LANG) }

    return new Promise(async (resolve, reject) => {
      this.getQueryWithParams(`${env.apiRouteSerieOffline}/${serieId}`, await this.parseQueryParams(params))
      .subscribe(res => resolve(res), err => reject(err))
    })
  }

  async getSeriesByType(typeId: number, findOptions: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(env.apiRouteSerieByType, typeId), await this.parseQueryParams(findOptions))
      .subscribe(resolve)
    })
  }

  async getSeriesByAuthor(authorId: number, findOptions: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(env.apiRouteSerieByAuthor, authorId), await this.parseQueryParams(findOptions))
      .subscribe(resolve)
    })
  }

  async getHentaiSeries(findOptionsDto: FindOptionsDto): Promise<Serie[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteHentaiSeries, await this.parseQueryParams(findOptionsDto)).subscribe(resolve)
    })
  }

  canAccessSerie(serieId: number): Observable<boolean> {
    return this.getQuery(String.format(env.apiCanAccessSerie, serieId))
  }

  public async getSerieFormats(): Promise<{ formats: SERIE_FORMAT[] }> {
    return new Promise(async resolve => {
      this.getQuery(env.apiRouteSerieFormats).subscribe(resolve)
    })
  }

  public async getSeriesByFormat(format: SERIE_FORMAT, findOptions: FindOptionsDto): Promise<{ data: Serie[] }> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(env.apiRouteSerieByFormat, format), await this.parseQueryParams(findOptions)).subscribe(resolve)
    })
  }


}
