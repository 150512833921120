import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss'],
})
export class RatingStarsComponent {
  @Input() rating = 0
  @Input() interactable = false
  @Input() size
  @Input() forceDark: boolean

  constructor() {}
}
