import { Action, createReducer, on } from "@ngrx/store"
import { EpubReaderState, initialState } from "./epub-reader.state"
import * as EpubReaderActions from "./epub-reader.actions"

const epubReaderReducer = createReducer(
  initialState,
  on(EpubReaderActions.setEpubLang, (state, { lang }) => ({ ...state, lang })),
  on(EpubReaderActions.setPercent, (state, { percent }) => ({ ...state, percent })),
  on(EpubReaderActions.setIsSwiping, (state, { isSwiping }) => ({ ...state, isSwiping })),
  on(EpubReaderActions.setPosition, (state, { position }) => ({ ...state, position })),
  on(EpubReaderActions.setIsReady, (state, { isReady }) => ({ ...state, isReady })),
  on(EpubReaderActions.setLocations, (state, { locations }) => ({ ...state, locations })),
  on(EpubReaderActions.setFontSize, (state, { fontSize }) => ({ ...state, fontSize })),
  on(EpubReaderActions.reset, (state) => ({
    ...initialState,
    lang: state.lang,
    fontSize: state.fontSize,
    percent: state.percent,
  }))
)

export function reducer(state: EpubReaderState | undefined, action: Action) {
  return epubReaderReducer(state, action)
}
