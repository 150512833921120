export enum PRODUCT_PLATFORM {
  STRIPE = 'STRIPE',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export interface SubscriptionProduct {
  id: string
  platform: PRODUCT_PLATFORM
  name: string
  countryCode: string
  price?: number //Viene dado por el proveedor
  currency?: string //Viene dado por el proveedor
  active: boolean
}

export interface IAPProduct {
  id: string
  platform: PRODUCT_PLATFORM
  name: string
  amount: number
  extraAmount: number
  iconName: string
  isBestChoice: boolean
  order: number
  price?: number //Viene dado por el proveedor
  currency?: string //Viene dado por el proveedor
}

export interface StripeCheckoutSession {
  sessionId: string
}
