import { createAction, props } from "@ngrx/store"

export const loadChapter = createAction(
  "[Chapter] Load Chapter",
  props<{ chapterID: number, lang: number }>()
)

export const loadChapterSuccess = createAction(
  "[Chapter] Load Chapter Success",
  props<{ chapter: any }>()
)

export const toogleFavoritePage = createAction(
  "[Chapter] Toggle Favorite Page",
  props<{ number: number, isFavorite: boolean }>()
)

export const increaseCommentCount = createAction(
  "[Chapter] Increase Comment Count"
)

export const reset = createAction("[Chapter] Reset")
