import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { map, mergeMap } from "rxjs/operators"
import { VolumeApiService } from "src/app/lib/services/api/modules/volume.api"
import * as VolumeActions from "./volume.actions"

@Injectable()

export class VolumeEffects {
  constructor(
    private actions: Actions,
    private volumeApiService: VolumeApiService
  ) {}

  fetchVolume$ = createEffect(() => 
    this.actions.pipe(
      ofType(VolumeActions.loadVolume),
      mergeMap(action => this.volumeApiService.getVolumeDetailRX(action.volumeID).pipe(
        map(volume => VolumeActions.loadVolumeSuccess({ volume }))
      ))
    )
  )
} // end_serieEffects 
