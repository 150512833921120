import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SerieCoverPipe } from './serie-cover.pipe'
import { VolumeCoverPipe } from './volume-cover.pipe'
import { SerieBannerPipe } from './serie-banner.pipe'
import { VolumeBannerPipe } from './volume-banner.pipe'
import { PagePipe } from './page.pipe'
import { SecurePipe } from './secure.pipe'
import { HomeBannerPipe } from './home-banner.pipe'
import { AvatarPipe } from './avatar.pipe'
import { CommentImagePipe } from './comment-image.pipe'
import { CommentAvatarPipe } from './comment-avatar.pipe'
import { OfflineSecurePipe } from './offline-secure.pipe'
import { AuthorAvatarPipe } from './author-avatar.pipe'
import { EditorialLogoPipe } from './editorial-logo.pipe'
import { MessageImagePipe } from './message-image.pipe'
import { LinkImagePipe } from './link-image.pipe'
import { PlaceholderPagePipe } from './placeholder-page.pipe'
import { EllipsisPipe } from './ellipsis.pipe'

@NgModule({
  declarations: [
    SerieCoverPipe,
    SerieBannerPipe,
    VolumeCoverPipe,
    VolumeBannerPipe,
    PagePipe,
    SecurePipe,
    HomeBannerPipe,
    AvatarPipe,
    CommentImagePipe,
    CommentAvatarPipe,
    OfflineSecurePipe,
    AuthorAvatarPipe,
    EditorialLogoPipe,
    MessageImagePipe,
    LinkImagePipe,
    PlaceholderPagePipe,
    EllipsisPipe
  ],
  imports: [CommonModule],
  exports: [
    VolumeBannerPipe,
    SerieBannerPipe,
    VolumeCoverPipe,
    SerieCoverPipe,
    PagePipe,
    SecurePipe,
    HomeBannerPipe,
    AvatarPipe,
    CommentImagePipe,
    CommentAvatarPipe,
    OfflineSecurePipe,
    AuthorAvatarPipe,
    EditorialLogoPipe,
    MessageImagePipe,
    LinkImagePipe,
    PlaceholderPagePipe,
    EllipsisPipe
  ],
})
export class PipesModule {}
