import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { env } from 'src/environments/env'
import { SK, UtilService } from './util.service'
import { StorageService } from './storage.service'
import OneSignal from 'onesignal-cordova-plugin';
import { Platform } from '@ionic/angular'
import { UserApiService } from './api/modules/user.api'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import { Store } from '@ngrx/store'
import { State } from '../store'
import { setPushNotifEnabled } from '../store/auth'

export enum PUSH_TYPE {
  DIRECT_MESSAGE = 'direct_message',
  SERIE_NEW = 'serie_new',
  SOCIAL_NEW = 'social_new',
  COMMENT_SERIE_FOLLOW = 'comment_serie_follow',
  REPLY_COMMENT = 'reply_comment',
  LIKE_COMMENT = 'like_comment',
  SOCIAL_NEWS = 'social_news',
}

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    private storage: StorageService,
    private router: Router,
    private platform: Platform,
    private userApiService: UserApiService,
    private store: Store<State>,
    private zone: NgZone,
    public utilService: UtilService,
  ) {}

  public async requestOnInit() {
    if (!this.platform.is('cordova')) return

    const reqDate = await this.storage.get(SK.PUSH_REQ_TIME)
    const reqCount = await this.storage.get(SK.PUSH_REQ_COUNT) || 0

    if (await this.utilService.weekTrigger(reqDate,reqCount)) {
      await this.storage.set(SK.PUSH_REQ_TIME, new Date().toISOString())
      await this.storage.set(SK.PUSH_REQ_COUNT, reqCount + 1)
      await this.requestPermission()
    }
  }

  public async requestPermission(): Promise<boolean> {
    if (!this.platform.is('cordova')) return

    const accepted = await OneSignal.Notifications.requestPermission(true)
    const oneSignalId = await OneSignal.User.pushSubscription.getIdAsync()
    await this.storage.set(SK.ONE_SIGNAL_ID, oneSignalId)
    await this.userApiService.addOneSignalIdToSession()

    return accepted
  }

  public async initialize() {
    if (!this.platform.is('cordova')) return

    OneSignal.Debug.setLogLevel(6)
    OneSignal.initialize(env.oneSignalAppId)

    this.addNotificationOpenedHandler()

    OneSignal.Notifications.addEventListener('permissionChange', e => {
      this.store.dispatch(setPushNotifEnabled({ pushNotifEnabled: e }))
    })

    const oneSignalId = await OneSignal.User.pushSubscription.getIdAsync()
    await this.storage.set(SK.ONE_SIGNAL_ID, oneSignalId)

    const permission = await OneSignal.Notifications.getPermissionAsync()

    this.store.dispatch(setPushNotifEnabled({ pushNotifEnabled: permission }))
  }

  public async hasPermission(): Promise<boolean> {
    return await OneSignal.Notifications.getPermissionAsync()
  }

  public async openDeviceSettings() {
    if (this.platform.is('android')) {
      return await NativeSettings.openAndroid({
        option: AndroidSettings.AppNotification
      })
    } else if (this.platform.is('ios')) {
      return await NativeSettings.openIOS({
        option: IOSSettings.App,
      })
    }

  }

  private addNotificationOpenedHandler() {
    OneSignal.Notifications.addEventListener('click', async e => {
      const clickData = e.notification
      const data: any = clickData.additionalData

      const type: PUSH_TYPE = data.type

      switch (type) {
        case PUSH_TYPE.SOCIAL_NEWS:
          this.zone.run(async () => await this.router.navigate(['/main/tabs/account&launchNews=true']))
          break
        case PUSH_TYPE.COMMENT_SERIE_FOLLOW:
          this.zone.run(async () => await this.router.navigate(
            [`/comments/comment/${data.commentId}?chapterId=${data.chapterId}&volumeId=${data.volumeId}&serieId=${data.serieId}`]))
          break
        case PUSH_TYPE.REPLY_COMMENT:
          this.zone.run(async () => await this.router.navigate(
            [`/comments/comment/${data.commentId}?chapterId=${data.chapterId}&volumeId=${data.volumeId}&serieId=${data.serieId}`]))
          break
        case PUSH_TYPE.LIKE_COMMENT:
          this.zone.run(async () => await this.router.navigate(
            [`/comments/comment/${data.commentId}?chapterId=${data.chapterId}&volumeId=${data.volumeId}&serieId=${data.serieId}`]))
          break
        }
          
    })
  }
}
