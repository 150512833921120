export interface ChapterState {
  id: number;
  isLoading: boolean;
  isLoaded: boolean;
  chapter: any;
} // end_SerieState

export const initialState: ChapterState = {
  id: null,
  isLoading: false,
  isLoaded: false,
  chapter: null,
} // end_initialState