import { createAction, props } from "@ngrx/store"

export const getSerie = createAction(
  "[Serie] Get Serie",
  (serie: any) => ({ serie })
)

export const loadSerie = createAction(
  "[Serie] Fetch Serie",
  props<{ serieID: number }>()
)

export const loadSerieSuccess = createAction(
  "[Serie] Fetch Serie Success",
  props<{ serie: any }>()
)

export const reset = createAction("[Serie] Reset")