import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'

import { env } from '../../environments/env'
import { Injectable } from '@angular/core'
import { ConnectionStatus } from '@capacitor/network'

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  networkStatus: ConnectionStatus
  isLoggedIn: boolean

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiKey = env.apiKey
    if (apiKey) {
      let authReq = req
      authReq = req.clone({
        headers: req.headers.append('apikey', apiKey),
      })
      if (!req.urlWithParams.includes('static')) {
      } //NO BORRAR
      return next.handle(authReq)
    }
  }
}
