<div class = "suggestedContainer" *ngIf="this.globalService.getIsSuggestedOpen()">
    <ion-label>
        <p>{{'STORE_POPOVER.APP_HEADER' | translate}}</p>
    </ion-label>
    <div class = "suggestedButtonsContainer">
        <ion-button size = "small" (click)="this.goToDownloadPage()">
            <ion-label>{{'STORE_POPOVER.DOWNLOAD_BUTTON' | translate}}</ion-label>
        </ion-button>
        <ion-button size = "small" (click)="this.close()">
            <ion-label>{{'STORE_POPOVER.CANCEL_BUTTON' | translate}}</ion-label>
        </ion-button>
    </div>
</div>
