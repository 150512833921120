import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { env } from './environments/env'
//import { defineCustomElements } from '@ionic/pwa-elements/loader'

if (env.production) {
  enableProdMode()

  if (window) {
    window.console.log = function () {}
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))

/*
  defineCustomElements(window)
  */
