import { Component, ViewChild } from "@angular/core"
import { ModalController } from "@ionic/angular";
import { UserApiService } from "src/app/lib/services/api/modules/user.api";

@Component({
  selector: "app-select-birthdate",
  templateUrl: "./select-birthdate.component.html",
  styleUrls: ["./select-birthdate.component.scss"],
})
export class SelectBirthDateComponent {
  private dateValue: string = ''

  public badDate: boolean = false

  constructor(
    private modalController: ModalController,
    private userApiService: UserApiService,
  ) {}

  onChange(event) {
    this.dateValue = event.detail.value
  }

  cancel() {
    return this.modalController.dismiss(null, 'cancel')
  }

  confirm() {
    if (!this.dateValue) return this.badDate = true

    const year = this.dateValue.split('-')[0]
    if (parseInt(year) < 1900 || parseInt(year) > new Date().getFullYear()) {
      this.badDate = true
      return
    } else {
      this.badDate = false
    }
  
    const date = new Date(this.dateValue)

    this.userApiService.submitBirthDate(date).then((res) => {
      if (res.valid) {
        return this.modalController.dismiss(null, 'confirm')
      } else this.badDate = true
    })

  }
}