import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { AuthInterceptor } from './interceptors/auth.interceptor'
import { HttpErrorInterceptor } from './interceptors/error.interceptor'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { Deeplinks } from '@ionic-native/deeplinks/ngx'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { ServiceWorkerModule } from '@angular/service-worker'
import { env } from '../environments/env'
import { File } from '@ionic-native/file/ngx'
import { NetworkHandler } from './lib/services/network-handler.service'
import { Brightness } from '@ionic-native/brightness/ngx'
import { IonicStorageModule } from '@ionic/storage-angular'
import { ResponseInterceptor } from './interceptors/response.interceptor'
import { ApiKeyInterceptor } from './interceptors/apikey.interceptor'
import VersionInterceptor from './interceptors/version.interceptor'
import { Clipboard } from '@ionic-native/clipboard/ngx'
import { WebView } from '@ionic-native/ionic-webview/ngx'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EffectsModule } from '@ngrx/effects'
import { reducers } from './lib/store'
import { EffectsList } from './lib/store'
export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json')
}
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io'
import { StorageService } from './lib/services/storage.service'
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx'
import { ComponentsModule } from './components/components.module'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx'
import { UserApiService } from './lib/services/api/modules/user.api'
import { CountryApiService } from './lib/services/api/modules/country.api'
import { ProductApiService } from './lib/services/api/modules/product.api'
import { ChapterApiService } from './lib/services/api/modules/chapter.api'
import { VolumeApiService } from './lib/services/api/modules/volume.api'
import { AuthApiService } from './lib/services/api/modules/auth.api'
import { SerieApiService } from './lib/services/api/modules/serie.api'
import { RemoteSettingsApiService } from './lib/services/api/modules/remoteSettings.api'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

export function onAppInit(svc: StorageService): () => Promise<any> {
  return svc.initialize
}

const config: SocketIoConfig = {
  url: env.apiUrl,
  options: {
    //withCredentials: false,
    autoConnect: false,
  },
}

@NgModule({ schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    declarations: [AppComponent],
    exports: [],
    bootstrap: [AppComponent], imports: [ComponentsModule,
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            animated: true,
        }),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: LanguageLoader,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: env.production }),
        SocketIoModule.forRoot(config),
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: env.production,
        }),
        EffectsModule.forRoot(EffectsList)], providers: [
        UserApiService,
        ChapterApiService,
        CountryApiService,
        ProductApiService,
        VolumeApiService,
        AuthApiService,
        SerieApiService,
        RemoteSettingsApiService,
        Brightness,
        Deeplinks,
        SignInWithApple,
        NetworkHandler,
        StatusBar,
        AppVersion,
        File,
        WebView,
        StorageService,
        ScreenOrientation,
        { provide: APP_INITIALIZER, useFactory: onAppInit, deps: [StorageService], multi: true },
        Clipboard,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
