import { Action, createReducer, on } from "@ngrx/store"
import { AuthState, initialState } from "./auth.state"
import * as AuthActions from "./auth.actions"

const authReducer = createReducer(
  initialState,
  on(AuthActions.setUser, (state, { user }) => ({ ...state, user })),
  on(AuthActions.setCanEnterShop, (state, { canEnterShop }) => ({ ...state, canEnterShop })),
  on(AuthActions.setPushNotifEnabled, (state, { pushNotifEnabled }) => ({ ...state, pushNotifEnabled }))
)

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action)
}