import { Injectable } from '@angular/core'
import { Genre } from '../interfaces/genre/genre.model'
import { Lang } from '../interfaces/lang/lang.model'
import { User } from '../interfaces/user/user.model'
import { StorageService } from './storage.service'
import { UtilService } from './util.service'

export enum USER_TYPE {
  PRESS = 'press',
  EDITORIAL = 'editorial',
  USER = 'user',
  AUTHOR = 'author',
}

export enum USER_STATUS {
  REGISTERED = 'registered',
  SUBSCRIBED = 'subscribed',
}

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  user: User = null
  favGenresText: string
  subscriptionIOS: any
  hasToken: boolean
  disabledIAP: boolean = false
  unlinked: boolean = false
  userValid: boolean = false
  private isSuggestedOpen: boolean = false

  constructor(public storage: StorageService, private utilService: UtilService) {}

  getGlobalUser(): User {
    return this.user
  }

  public getIsSuggestedOpen(): boolean {
    return this.isSuggestedOpen
  } // end_getIsSuggestedOpen

  public setIsSuggestedOpen(value: boolean): void {
    this.isSuggestedOpen = value
  }// end_setIsSuggestedOpen

  increaseCoins(amount: number) {
    this.user.coins += amount
  }

  setFavGenres(favGenres: Genre[]) {
    this.user.favouriteGenres = favGenres
  }

  setFavLangs(favLangs: Lang[]) {
    this.user.favouriteLangs = favLangs
  }

  async setGlobalUser(usr: User) {
    this.user = usr
    if (this.user?.type === USER_TYPE.AUTHOR && this.user?.author) {
      for (const role of this.user.author.roles) {
        let r = role.role
        role.role = await this.utilService.getRoleTranslation(r)
      }
    }
    if (usr?.type == USER_TYPE.PRESS || usr?.type == USER_TYPE.EDITORIAL) {
      this.user.status = USER_STATUS.SUBSCRIBED
    }
  }

  isUserSubscribed() {
    if (this.disabledIAP) {
      return true
    }
    return (
      this.user?.status === USER_STATUS.SUBSCRIBED ||
      this.user?.type === USER_TYPE.PRESS ||
      this.user?.type === USER_TYPE.EDITORIAL
    )
  }

  tokenReceived() {
    this.hasToken = true
  }

  tokenRemoved() {
    this.hasToken = false
  }
}
