export interface EpubReaderState {
  lang: number,
  isSwiping: boolean,
  percent: { value: number, slide: boolean },
  position: { current: number, total: number },
  isReady: boolean
  locations: { cfi: string, page: number }[],
  fontSize: number
}

export const initialState: EpubReaderState = {
  lang: null,
  isSwiping: false,
  percent: { value: 0, slide: false },
  position: { current: 0, total: 0 },
  isReady: false,
  locations: [],
  fontSize: 100
}