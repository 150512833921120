import { Injectable } from "@angular/core"
import { Countries } from "src/app/lib/interfaces/country/country.model"
import { env } from "src/environments/env"
import { ApiService } from "../api.service"

@Injectable()
export class CountryApiService extends ApiService {
  async getCountries(): Promise<Countries> {
    return new Promise<Countries>((resolve) => {
      this.getQuery(env.apiRouteGetCountries).subscribe(resolve)
    })
  }

  updateCountry(countryId: number): Promise<any> {
    return new Promise(resolve => {
      this.updateQuery(env.apiRouteChangeCountry, { countryId }).subscribe(resolve)
    })
  }
}