import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'

import { Injectable } from '@angular/core'
import { from } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { ToastService } from '../lib/services/toast.service'

import { ConnectionStatus } from '@capacitor/network'
import { env } from '../../environments/env'
import { SK } from '../lib/services/util.service'
import { StorageService } from '../lib/services/storage.service'
import { Observable } from 'rxjs'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  networkStatus: ConnectionStatus
  constructor(private toast: ToastService, private storage: StorageService) {}

  isLoggedIn: boolean

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.storage.get(SK.TOKEN)).pipe(
      switchMap((token) => {
        let authReq = req
        if (token != null) {
          authReq = req.clone({
            headers: req.headers.append('Authorization', `Bearer ${token}`),
          })
        }
        if (!env.production) {
          authReq = authReq.clone({
            headers: authReq.headers.append('skipmaintenance', env.apiKeySkipMaintenance),
          })
        }
        return next.handle(authReq)
      }),
    )
  }
}
