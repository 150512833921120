<ion-content *ngIf="this.global.getGlobalUser()">
  <ion-grid class="ion-padding">
    <ion-row>
      <h2>
        <ion-text color="primary">{{ 'CHAPTER_ACCESS.TITLE' | translate }}</ion-text>
      </h2>
    </ion-row>

    <ion-row>
      <p>
        {{ 'CHAPTER_ACCESS.PURCHASE_QUESTION_A_1' | translate }}
        <ion-icon color="primary" class="coinIconMedium" name="server"></ion-icon>
      </p>
    </ion-row>

    <!--
        DE MOMENTO MIENTRAS NO HAYA SUSCRIPCIÓN
    <ion-row
      *ngIf="
        chapterProductStatus && this.global.isUserSubscribed() && !this.chapterProductStatus.availableForSubscription
      "
    >
      
        <p>
        {{ 'CHAPTER_ACCESS.NOT_AVAILABLE' | translate }}
        <a (click)="openInfinity()"><b>Infinity</b></a>
      </p>

      <p>
        {{ 'CHAPTER_ACCESS.PURCHASE_QUESTION_A_1' | translate }}
        <ion-icon color="primary" class="coinIconMedium" name="server"></ion-icon>
      </p>
    </ion-row>

    <ion-row
      *ngIf="
        chapterProductStatus && !this.global.isUserSubscribed() && this.chapterProductStatus.availableForSubscription
      "
    >
      
        <p>
        {{ 'CHAPTER_ACCESS.PURCHASE_QUESTION_B_1' | translate }}<a (click)="openInfinity()"><b>Infinity</b></a>
        {{ 'CHAPTER_ACCESS.PURCHASE_QUESTION_B_2' | translate }}
      </p>
    </ion-row>!-->

    <ion-row
      *ngIf="
        chapterProductStatus && !this.global.isUserSubscribed() && !this.chapterProductStatus.availableForSubscription
      "
    >
      <p>{{ 'CHAPTER_ACCESS.PURCHASE_QUESTION_C_1' | translate }}</p>
    </ion-row>

    <ion-row
      *ngIf="
        chapterProductStatus &&
        !this.chapterProductStatus.firstPurchase &&
        (this.isAutoPurchase === false || !this.isAutoPurchase)
      "
    >
      <ion-col size="1">
        <ion-checkbox checked="true" class="ion-margin-end" (ionChange)="autoPurchase($event)"></ion-checkbox>
      </ion-col>
      <ion-col>
        <ion-text color="medium">{{ 'CHAPTER_ACCESS.AUTOPURCHASE' | translate }}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-footer>
    <ion-toolbar>
      <ion-button mode="md" slot="start" fill="clear" class="touchable ion-margin-start" (click)="dismiss()">
        <ion-label color="dark">{{ 'WORD.CANCEL' | translate }}</ion-label>
      </ion-button>

      <ion-button
        mode="md"
        type="clear"
        class="touchable ion-margin-end"
        (click)="purchaseChapter()"
        slot="end"
        color="warning"
      >
        <ion-label class="price">{{ this.chapterProductStatus.price }} </ion-label>
        <ion-icon class="coinIconPrice" name="server-outline"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-footer>
  <ion-footer class="ion-text-center subfooter">
    <ion-text color="medium" class="cash"
      >{{ 'CHAPTER_ACCESS.YOUR_COINS' | translate }}
      {{ this.global.getGlobalUser().coins }}
      <ion-icon class="coinIconMini" color="primary" name="server"></ion-icon>
    </ion-text>
  </ion-footer>
</ion-content>
