import { Injectable } from "@angular/core"
import { env } from "src/environments/env";
import { ApiService } from "../api.service";
import { Device } from '@capacitor/device'
import { SK } from "../../util.service";
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto";

@Injectable()
export class AuthApiService extends ApiService {
  async getLoggedInSessions(): Promise<any> {
    return new Promise(resolve => {
      this.getQuery(env.apiRouteGetLoggedInSessions).subscribe(resolve)
    })
  }

  createAccout(
    email: string,
    password: string,
    username: string,
    gender: string,
    countryId: number,
    newsletter:boolean,
    deviceLangCode: string,
    appleId: string = '',
    googleId: string = '',
  ) {
    return this.postQuery(env.apiRouteRegister, {
      email,
      password,
      username,
      gender,
      countryId,
      newsletter: newsletter ? true : null,
      appleId,
      googleId,
      deviceLangCode,
    })
  }

  async login(email: string, password: string, appleId: string = '', googleId: string = ''): Promise<any> {
    const oneSignalId = await this.storage.get(SK.ONE_SIGNAL_ID)

    const { model, platform, operatingSystem, osVersion } = await Device.getInfo()
  
    const deviceLangCode = (await Device.getLanguageCode()).value

    return new Promise((resolve, reject) => {
      this.postQuery(env.apiRouteLogin, {
          email,
          password,
          model,
          platform,
          operatingSystem,
          osVersion,
          deviceLangCode,
          oneSignalId,
          appleId,
          googleId,
        })
        .subscribe(
          async (res) => {
            if (!res.sessions) {
              await this.storage.set(SK.SESSION_ID, res.sessionId)
              await this.storage.set(SK.TOKEN, res.token)
              await this.storage.set(SK.USER_ID, res.id)
              this.globalService.tokenReceived()
            }else {
              await this.storage.set(SK.TOKEN, res.token)
              await this.storage.set(SK.USER_ID, res.id)
            }
            resolve(res)
          },
          async (err) => {
            await this.storage.remove(SK.TOKEN)
            this.globalService.tokenRemoved()
            reject(err)
          },
        )
    })
  }

  async logoutSession(jwt: string): Promise<any> {
    const token = jwt
    return new Promise((resolve) => {
      this.http.put<any>(`${env.apiUrl}/${env.apiRouteLogoutSession}`, { token }).subscribe(async (res) => {
        resolve(res)
      })
    })
  }

  async logout(): Promise<boolean> {
    const token = await this.storage.get(SK.TOKEN)
    const sessionId = await this.storage.get(SK.SESSION_ID)

    return new Promise((resolve) => {
      this.postQuery(env.apiRouteLogout, { token, sessionId })
      .subscribe(async () => {
        await this.resetCredentials()
        resolve(true)
      })
    })
  }

  public async resetCredentials() {
    await this.storage.remove(SK.USER_ID)
    await this.storage.remove(SK.SESSION_ID)
    await this.storage.remove(SK.TOKEN)
    this.globalService.setGlobalUser(null)
    this.globalService.tokenRemoved()
  }

  async changePassword(oldPassword: string, newPassword: string) {
    return new Promise(resolve => {
      this.updateQuery(env.apiRouteChangePsw, { oldPassword, newPassword }).subscribe(resolve)
    })
  }

  public async sendDeleteEmail(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postQuery(env.apiRouteSendDeleteEmail, {}).subscribe(
        res => resolve(res), 
        err => reject(err)
      )
    })
  }

  public async confirmDelete(pin: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.removeQuery(`${env.apiRouteConfirmDelete}/${pin}`).subscribe(
        res => resolve(res),
        err => reject(err)
      )
    })
  }

  recoverPassword(email: string) {
    return new Promise(resolve => {
      this.postQuery(env.apiRouteRecoverPassword, { email }).subscribe(resolve)
    })
  }

  async checkResetPasswordKey(email: string, key: string): Promise<boolean> {
    const params: FindOptionsDto = {
      customParams: {
        email,
        key,
      }
    }

    return new Promise(async resolve => {
      this.getQueryWithParams(env.apiRouteCheckRecoverPasswordKey, await this.parseQueryParams(params)).subscribe(resolve)
    })
  }

  async resetPasswordKey(email: string, key: string, password: string): Promise<any> {
    return new Promise(resolve => {
      this.updateQuery(env.apiRouteResetPassword, { email, key, password}).subscribe(resolve)
    })
  }

  async checkJWT(redirect = false): Promise<boolean> {
    // esto salta en el cambio de páginas
    const token = await this.storage.get(SK.TOKEN)

    const connected = await this.networkHandler.isNetworkConnected()
    return new Promise(async (resolve) => {
      if (!connected) return resolve(true)

      if (token === null) {
        if (redirect) this.navCtrl.navigateRoot('/login')
        else {
          this.alertService
            .confirmAlert('ALERT.LOGIN_REQUIRED', 'ALERT.LOGIN_REQUIRED_EXPLANATION', 'ALERT.LOGIN', 'WORD.CANCEL')
            .then((confirm) => {
              if (confirm) {
                this.navCtrl.navigateRoot('/login')
              }
            })
        }

        resolve(false)
      } else {
        this.getQuery(env.apiRouteCheckJWT).subscribe(
          async (resp) => {
            await this.storage.set(SK.TOKEN, resp.token)
            resolve(resp.valid)
          },
          () => {
            resolve(false)
          },
        )
      }
    })
  }

  resendEmailValidationEmail(email: string): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(env.apiRouteSendEmailValidationEmail, { email }).subscribe(resolve)
    })
  }

  resendValidationEmail(email: string): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(env.apiRouteSendAccountValidationEmail, { email }).subscribe(resolve)
    })
  }
}
