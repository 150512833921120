import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  lang: string
  langReady: boolean = false
  constructor(private translate: TranslateService) {}

  getDefaultLanguage() {
    let language = this.translate.getBrowserLang()
    this.translate.setDefaultLang(language)
    return language
  }

  async setLanguage(setLang) {
    return new Promise((resolve) => {
      this.translate.use(setLang).subscribe((res) => {
        this.langReady = true
        resolve(res)
      })
    })
  }

  async i18n(key: string): Promise<any> {
    return await this.translate.get(key).toPromise()
  }

  setDeviceLangCode(code: string) {
    this.lang = code
    moment.locale(this.lang)
  }

  getDeviceLangCode() {
    return this.lang
  }

  getLanguage(id: string) {
    switch (id) {
      case '1':
        return 'es'
      case '2':
        return 'en'
      case '3':
        return 'fr'
      case '4':
        return 'jp'
      case '5':
        return 'ca'
      default:
        return 'es'
    }
  }
}
