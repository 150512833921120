import { Injectable } from "@angular/core"
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto"
import { PublicUser } from "src/app/lib/interfaces/user/user.model"
import { ApiService } from "../api.service"
import { String } from "typescript-string-operations"
import { env } from "src/environments/env"

@Injectable()
export class SocialApiService extends ApiService {
  async getFollowers(id: string, findOptions: FindOptionsDto): Promise<PublicUser[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(env.apiRouteGetFollowers, id), await this.parseQueryParams(findOptions))
      .subscribe(resolve)
    })
  }

  async getFollowUser(id: string): Promise<boolean> {
    return new Promise(resolve => {
      this.getQuery(String.format(env.apiRouteGetFollowUser, id)).subscribe(res => {
        if (res) resolve(true)
        else resolve(false)
      })
    })
  }

  async followUser(userIdToFollow: string): Promise<boolean> {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteFollowUser, userIdToFollow), { follow: true }).subscribe(resolve)
    })
  }

  async unfollowUser(userIdToFollow: string): Promise<boolean> {
    return new Promise(resolve => {
      this.removeQuery(String.format(env.apiRouteUnfollowUser, userIdToFollow)).subscribe(() => resolve(false))
    })
  }

  async getFollowingUsers(id: string, findOptions: FindOptionsDto): Promise<PublicUser[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(env.apiRouteGetFollowingUsers, id), await this.parseQueryParams(findOptions))
      .subscribe(resolve)
    })
  }

  blockUnblockUser(otherUserId: string, isBlocked: boolean): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteBlockUser, otherUserId), { isBlocked }).subscribe(resolve)
    })
  }
}