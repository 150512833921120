import { Injectable } from '@angular/core'
import { UserApiService } from '../services/api/modules/user.api'
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { GlobalService } from '../services/global.service'
import { StorageService } from '../services/storage.service'
import { SK, UtilService } from '../services/util.service'
import { ModalController, PopoverController } from '@ionic/angular'
import { SelectBirthDateComponent } from 'src/app/components/popovers/select-birthdate/select-birthdate.component'
import { ToastService } from '../services/toast.service'
import { TranslateService } from '@ngx-translate/core'
import { SerieApiService } from '../services/api/modules/serie.api'
import { SelectFamilyModeComponent } from 'src/app/components/popovers/select-family-mode/select-family-mode.component'
import { ChapterAccessComponent } from 'src/app/components/popovers/chapter-access/chapter-access.component'

@Injectable({
  providedIn: 'root',
})
export class ExplicitGuard {
  constructor(
    private userApiService: UserApiService,
    private router: Router,
    private global: GlobalService,
    private storage: StorageService,
    private modalController: ModalController,
    private toastService: ToastService,
    private translateService: TranslateService,
    private serieApiService: SerieApiService,
    private popoverController: PopoverController,
    private utilService: UtilService,
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const serieId = route.params.serieId || route.queryParams.serieId
    console.log({ serieId })
    const serieDetail = await this.serieApiService.getSerieDetail(serieId)

    let valid = true

    if (serieDetail.explicitContent) {
      // check enough age
      if (!(await this.userApiService.checkBirthDate(serieId)).valid) {
        // if birthdate null show popup to put birthdate
        if (!(await this.checkBirthDate(serieId))) {
          valid = false
        }
      }

      if (!(await this.checkFamilyMode())) {
        valid = false
      }
    }

    if (valid) return true
    else {
      return false
    }
  }

  private async checkBirthDate(serieId: number): Promise<boolean> {
    const res = await this.userApiService.checkBirthDate(serieId)

    if (res.message === 'NO_BIRTHDATE') {
      const popover = await this.modalController.create({
        component: SelectBirthDateComponent,
      })

      await popover.present()
      const data = await popover.onDidDismiss()

      if (data.role === 'confirm') {
        const res = await this.userApiService.checkBirthDate(serieId)
        return res.valid
      } else return false
    } else if (!res.valid) {
      this.toastService.presentToast(this.translateService.instant('SERIE.EXPLICIT_ACCESS_DENIED'))
    }

    return res.valid
  }

  private async checkFamilyMode(): Promise<boolean> {
    // if not global user set
    if (this.global.getGlobalUser() === null) {
      const userId = await this.storage.get(SK.USER_ID)
      if (userId && userId !== '') {
        const usr = await this.userApiService.getUser(userId)
        this.global.setGlobalUser(usr)
      }
    }

    // if family mode disabled show popover
    if (
      this.global.getGlobalUser() == null ||
      (this.global.getGlobalUser() != null && this.global.getGlobalUser().familyMode)
    ) {
      let cssClass: string
      if (this.utilService.isDesktop()) cssClass = 'popover-family-mode-desktop'
      const popover = await this.popoverController.create({
        cssClass,
        showBackdrop: true,
        translucent: true,
        animated: false,
        component: SelectFamilyModeComponent,
      })

      await popover.present()

      const { data } = await popover.onDidDismiss()

      if (data !== null && data !== undefined) {
        if (data.data == true) {
          return true
        }
      }
      return false
    }

    return true
  }
}
