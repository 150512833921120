import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage-angular'

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null

  constructor(private storage: Storage) {}

  initialize = (): Promise<any> => {
    return new Promise(async (resolve) => {
      const storage = await this.storage.create()
      this._storage = storage
      resolve(true)
    })
  }

  public async set(key: string, value: any) {
    await this._storage?.set(key, value)
  }

  public async get(key: string) {
    return await this._storage?.get(key)
  }

  public async remove(key: string) {
    await this._storage.remove(key)
  }
}
