<app-header>
  <ion-title>{{'SHOP.TITLE' | translate}}</ion-title>
  <ion-label *ngIf="this.global.getGlobalUser()" slot="end" class="ion-margin-end">
    <b style="margin-right: 5px;">{{this.global.getGlobalUser().coins}}</b>
    <ion-icon color="primary" class="coinIcon" name="server"></ion-icon>
  </ion-label>
</app-header>

<ion-content *ngIf="this.productsService.iapProducts">
  <ion-grid>
    <ion-list class="itemsContainer">
      <ion-item *ngFor="let iapProduct of this.productsService.iapProducts">
        <ion-label>
          <h2>
            <b style="margin-right: 5px;">{{iapProduct.amount + iapProduct.extraAmount}}</b>
            <ion-icon color="primary" class="coinIcon" name="server"> </ion-icon>
          </h2>
          <p *ngIf="iapProduct.extraAmount>0">
            {{iapProduct.amount}}<ion-text color="primary"> +{{iapProduct.extraAmount}} </ion-text>
            <ion-text color="primary" class="smallText"><b>{{'WORD.FREE' | translate}}</b></ion-text>
          </p>
        </ion-label>

        <ion-button
          *ngIf="iapProduct.price"
          class="buyButton"
          size="medium"
          color="warning"
          mode="md"
          (click)="purchaseIAP(iapProduct)"
        >
          <b>{{this.productsService.getFormattedPrice(iapProduct.price.toString(), iapProduct.currency)}}</b>
        </ion-button>
      </ion-item>
    </ion-list>
  </ion-grid>
  <ion-modal 
    [isOpen]="this.openModal" 
    [initialBreakpoint]="1" 
    [breakpoints]="[0, 1]" 
    class="successModal" 
    (didDismiss)="this.closeModal()" 
    handleBehavior="cycle"
  >
    <ng-template>
      <app-purchase-success [iapProduct]="this.purchasedIAP"></app-purchase-success>
    </ng-template>
  </ion-modal>
</ion-content>

