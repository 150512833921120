import { createAction, props } from "@ngrx/store"
import { User } from "src/app/lib/interfaces/user/user.model"

export const setUser = createAction(
  "[Auth] Set User",
  props<{ user: User }>()
)

export const setCanEnterShop = createAction(
  "[Auth] Set Can Enter Shop",
  props<{ canEnterShop: boolean }>()
)

export const setPushNotifEnabled = createAction(
  "[Auth] Set Push Notif Enabled",
  props<{ pushNotifEnabled: boolean }>()
)