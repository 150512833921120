import { Component, NgZone } from '@angular/core'
import { Platform, NavController } from '@ionic/angular'
import { SK, UtilService, READ_MODE } from './lib/services/util.service'
import { ApiService } from './lib/services/api.service'
import { GlobalService } from './lib/services/global.service'
import { NetworkHandler } from './lib/services/network-handler.service'
import { AlertService } from './lib/services/alert.service'
import { PushNotificationsService } from './lib/services/push-notifications.service'
import { App } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { Device } from '@capacitor/device'
import { ProductsService } from './lib/services/products.service'
import { LocalizationService } from './lib/services/localization.service'
import { Router } from '@angular/router'
import { LoadingService } from './lib/services/loading.service'
import { ToastService } from './lib/services/toast.service'
import { Deeplinks } from '@ionic-native/deeplinks/ngx'
import { ShopPage } from './pages/shop/shop.page'
import { StorageService } from './lib/services/storage.service'
import CloseOverlaysService from './lib/services/close-overlays.service'
import { StatusBar, Style } from '@capacitor/status-bar'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { UserApiService } from './lib/services/api/modules/user.api'
import { ProductApiService } from './lib/services/api/modules/product.api'
import { register } from 'swiper/element/bundle';
import { Store } from '@ngrx/store'
import { State, selectUser } from './lib/store'
import * as AuthActions from './lib/store/auth/auth.actions'
import { NewsletterService } from './lib/services/newsletter.service'

register()

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public platform: Platform,
    private storage: StorageService,
    public apiService: ApiService,
    public global: GlobalService,
    public networkHandler: NetworkHandler,
    public alertService: AlertService,
    private pushNotificationsService: PushNotificationsService,
    private productsService: ProductsService,
    public util: UtilService,
    public localizationService: LocalizationService,
    private router: Router,
    private zone: NgZone,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private navCtrl: NavController,
    private deeplinks: Deeplinks,
    private closeOverlaysService: CloseOverlaysService,
    private screenOrientation: ScreenOrientation,
    private userApiService: UserApiService,
    private productApiService: ProductApiService,
    private store: Store<State>,
    private newsletterService: NewsletterService,
  ) {
    this.platform.backButton.subscribe(async () => {
      this.loadingService.hide()
    })
    
    if (this.util.isCapacitor() && !this.util.isTablet()) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)
    }

    this.initializeApp()
    this.checkAppVersion()
    this.closeOverlaysService.onBackButton()
    this.util.handleBackButton()
  } // end_constructor

  private async checkAppVersion() {
    if (this.util.getPlatform() !== 'web') {
      await this.productApiService.checkVersion()
    } // end_if
  } //  end_checkAppVersion

  async initializeApp() {
    const token = await this.storage.get(SK.TOKEN)
    this.global.hasToken = token && true
    
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        const slug = data.url.split('.com').pop() || data.url.split('.es').pop() || data.url.split('.net').pop()
        if (slug) {
          this.router.navigateByUrl(slug)
        }
      })
    })

    SplashScreen.show()

    const userId = await this.storage.get(SK.USER_ID)
    this.handleTheme()

    let lang = await this.storage.get(SK.APP_LANG)
    if (!lang) {
      const deviceLangCode = (await Device.getLanguageCode()).value
      const _lang = deviceLangCode.substr(0, 2).toLowerCase()
      if (_lang === 'es' || _lang === 'en' || _lang === 'jp' || _lang === 'fr' || _lang === 'ca') {
        this.localizationService.setDeviceLangCode(_lang)
        lang = _lang
      } else {
        this.localizationService.setDeviceLangCode('en')
        lang = 'en'
      }
    }

    await this.localizationService.setLanguage(this.localizationService.getLanguage(lang))

    const connected = await this.networkHandler.isNetworkConnected()
    if (!connected) {
      if (lang === '1') {
        this.localizationService.setDeviceLangCode('es')
      } else if (lang === '2') {
        this.localizationService.setDeviceLangCode('en')
      } else if (lang === '3') {
        this.localizationService.setDeviceLangCode('fr')
      } else if (lang === '4') {
        this.localizationService.setDeviceLangCode('jp')
      } else if (lang === '5') {
        this.localizationService.setDeviceLangCode('ca')
      }

      this.networkHandler.showOfflineContentAlert()
      //SplashScreen.hide()
      return
    }

    if (userId) {
      const user = await this.userApiService.getUser(userId)
      this.store.dispatch(AuthActions.setUser({ user }))
      if (user) {
        this.global.userValid = true
        this.localizationService.setDeviceLangCode(user.deviceLangCode)
        if (user.deviceLangCode === 'es') {
          await this.storage.set(SK.APP_LANG, '1')
        } else if (user.deviceLangCode === 'en') {
          await this.storage.set(SK.APP_LANG, '2')
        } else if (user.deviceLangCode === 'fr') {
          await this.storage.set(SK.APP_LANG, '3')
        } else if (user.deviceLangCode === 'jp') {
          await this.storage.set(SK.APP_LANG, '4')
        } else if (user.deviceLangCode === 'ca') {
          await this.storage.set(SK.APP_LANG, '5')
        } else {
          await this.storage.set(SK.APP_LANG, '2')
        }
        await this.localizationService.setLanguage(
          this.localizationService.getLanguage(await this.storage.get(SK.APP_LANG)),
        )
      } else {
        this.global.userValid = false
      }
    } else {
      const deviceLangCode = (await Device.getLanguageCode()).value
      const lang = deviceLangCode.substr(0, 2).toLowerCase()
      if (lang === 'es') {
        await this.storage.set(SK.APP_LANG, '1')
      } else if (lang === 'en') {
        await this.storage.set(SK.APP_LANG, '2')
      } else if (lang === 'fr') {
        await this.storage.set(SK.APP_LANG, '3')
      } else if (lang === 'jp') {
        await this.storage.set(SK.APP_LANG, '4')
      } else if (lang === 'ca') {
        await this.storage.set(SK.APP_LANG, '5')
      } else {
        await this.storage.set(SK.APP_LANG, '2')
      }
      await this.localizationService.setLanguage(
        this.localizationService.getLanguage(await this.storage.get(SK.APP_LANG)),
      )
    }

    const imageQuality = await this.storage.get(SK.IMAGE_QUALITY)
    if (!imageQuality) {
      await this.storage.set(SK.IMAGE_QUALITY, 'hq')
    }

    const preferenceReadingMode = await this.storage.get(SK.READING_MODE)
    if (!preferenceReadingMode) {
      await this.storage.set(SK.READING_MODE, READ_MODE.AUTOMATIC)
    }

    this.platform.ready().then(async () => {

      this.pushNotificationsService.initialize()
      
      this.store.select(selectUser).subscribe(async user => {
        if (!user) return

        await this.productsService.init(this.util.getProductPlatform(), user.id)
        await this.pushNotificationsService.requestOnInit()
        await this.newsletterService.requestOnInit()
      })

      // if (this.platform.is('cordova')) {
      //   this.setupDeeplinks()
      // }

      this.platform.pause.subscribe(() => {})

      this.platform.resume.subscribe(async () => {
        //SplashScreen.hide()
        const userId = await this.storage.get(SK.USER_ID)
        if (userId && userId !== '') {
          this.userApiService.getUser(userId).then((usr) => {
            this.global.setGlobalUser(usr)
          })
        }
      })

      // document.addEventListener("screenshot", () => {
      //   this.router.navigate(['/account'])
      // }, false)
      if (this.util.isCapacitor()) await PrivacyScreen.disable();
    })
  } // end_initializeApp

  private async handleTheme() {
    let darkMode = await this.storage.get(SK.DARK_MODE)

    if (darkMode == null) {
      await this.storage.set(SK.DARK_MODE, true)
      darkMode = true
    }

    document.body.classList.toggle('dark', darkMode)

    if (!this.util.isWeb()) {
      if (this.util.isiOS()) await StatusBar.setStyle({ style: Style[darkMode ? 'Dark' : 'Light']})
      StatusBar.show();
    }

  }

  onScreenClick() {
    this.toastService.dismissToast()
  }

  setupDeeplinks() {
    /*
    this.deeplinks.route({
      'settings': SettingsPage,
      '/settings': SettingsPage,
      '/main/tabs/search': SearchPage,
      '/main/tabs/account': AccountPage
    }).subscribe(
      match => {
        console.log('successfully matched')
        const internalPath = `/${match.$route}`
        this.zone.run(() => {
          this.router.navigateByUrl(internalPath)
        })
      }, nomatch => {
        console.log('Got a deeplink that did not match', nomatch)
      }
    )
    */

    this.deeplinks
      .routeWithNavController(this.navCtrl, {
        shop: ShopPage,
      })
      .subscribe(
        () => {
          // match.$route - the route we matched, which is the matched entry from the arguments to route()
          // match.$args - the args passed in the link
          // match.$link - the full link data
          //console.log('Successfully matched route', match)
        },
        () => {
          // nomatch.$link - the full link data
          //console.error('Got a deeplink that didn\'t match', nomatch)
        },
      )
  }
}
