<app-rating-star [forceDark]="forceDark" [size]="size" [starIndex]="1" [rating]="rating" [interactable]="interactable">
</app-rating-star>

<app-rating-star [forceDark]="forceDark" [size]="size" [starIndex]="2" [rating]="rating" [interactable]="interactable">
</app-rating-star>

<app-rating-star [forceDark]="forceDark" [size]="size" [starIndex]="3" [rating]="rating" [interactable]="interactable">
</app-rating-star>

<app-rating-star [forceDark]="forceDark" [size]="size" [starIndex]="4" [rating]="rating" [interactable]="interactable">
</app-rating-star>

<app-rating-star [forceDark]="forceDark" [size]="size" [starIndex]="5" [rating]="rating" [interactable]="interactable">
</app-rating-star>
