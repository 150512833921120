import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { map, mergeMap } from "rxjs/operators"
import { ChapterApiService } from "src/app/lib/services/api/modules/chapter.api"
import * as ChapterActions from "./chapter.actions"

@Injectable()

export class ChapterEffects {
  constructor(
    private actions$: Actions,
    private chapterApiService: ChapterApiService
  ) {}

  fetchChapter$ = createEffect(() => 
    this.actions$.pipe(
      ofType(ChapterActions.loadChapter),
      mergeMap(({ chapterID, lang }) => this.chapterApiService.getChapterRX(chapterID, lang).pipe(
        map(chapter => ChapterActions.loadChapterSuccess({ chapter })),
      )),
    )
  )
} // end_serieEffects 
