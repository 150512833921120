import { Injectable } from '@angular/core'
import { Observable, from } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { VolumeDetail } from 'src/app/lib/interfaces/volume/volume.model'
import { env } from 'src/environments/env'
import { SK } from '../../util.service'
import { ApiService } from '../api.service'

@Injectable()
export class VolumeApiService extends ApiService {
  async getVolumeDetail(volumeId: number): Promise<VolumeDetail> {
    const lang = await this.storage.get(SK.APP_LANG)

    return new Promise(async (resolve, reject) => {
      this.getQueryWithParams(`${env.apiRouteVolume}/${volumeId}`, await this.parseQueryParams({ lang })).subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }

  getVolumeDetailRX(volumeId: number): Observable<VolumeDetail> {
    return from(Promise.all([this.storage.get(SK.APP_LANG)])).pipe(
      switchMap(([lang]) => {
        return this.http.get<VolumeDetail>(`${env.apiUrl}/${env.apiRouteVolume}/${volumeId}?lang=${lang}`)
      }),
    )
  }

  async checkVolumeBought(volumeId: string, serieId: string): Promise<boolean> {
    return new Promise((resolve) => {
      return this.postQuery(`purchase/checkVolume`, { volumeId, serieId }).subscribe((res) =>
        resolve(res.volumeComplete),
      )
    })
  }
}
