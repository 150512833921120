import { Injectable, OnDestroy } from '@angular/core'
import { PluginListenerHandle } from '@capacitor/core'
import { Network, ConnectionStatus } from '@capacitor/network'

import { AlertService } from './alert.service'
import { NavController, Platform } from '@ionic/angular'
import { NavigationStart, Router } from '@angular/router'
import { GlobalService } from './global.service'
import { Subscription } from 'rxjs'
import { UtilService } from './util.service'

@Injectable()
export class NetworkHandler implements OnDestroy {
  networkStatus: ConnectionStatus
  networkListener: Promise<PluginListenerHandle>
  lastStatus: boolean = true
  currentUrlPath: string
  backButtonSubscription: Subscription

  constructor(
    public alertService: AlertService,
    private navCtrl: NavController,
    private router: Router,
    private global: GlobalService,
    private platform: Platform,
    private utilService: UtilService
  ) {
    if (this.utilService.isCapacitor()) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          // Could add more chars url:path?=;other possible
          const urlDelimitators = new RegExp(/[?//,;&:#$+=]/)
          this.currentUrlPath = event.url.slice(1).split(urlDelimitators)[0]
        }
      })
      this.startNetworkListener()
    } // end_if
  }

  async isNetworkConnected(): Promise<boolean> {
    return (await Network.getStatus()).connected
  }

  startNetworkListener() {
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.networkStatus = status

      if (this.lastStatus == status.connected) return

      this.lastStatus = status.connected
      let excludedURLs = ['offline-reader', 'offline-content-detail']

      if (!status.connected && !excludedURLs.some((r) => this.currentUrlPath.includes(r))) {
        this.showOfflineContentAlert()

      } else if (!this.lastStatus && excludedURLs.includes(this.currentUrlPath)) {
        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, (processNextHandler) => {
          this.showOfflineContentAlert()
          processNextHandler()
        })
      } // end_else_if

      if (this.lastStatus) {
        //si hay conexión
      }
    })
  }

  showRetryAlert() {
    this.alertService
      .oneButtonAlert('ALERT.NO_SUB_NO_INTERNET_TITLE', 'ALERT.NO_SUB_NO_INTERNET_MESSAGE', 'WORD.RETRY')
      .then((confirm) => {
        if (confirm) {
          //this.navCtrl.navigateRoot('main/tabs/home');
          if (!this.lastStatus) {
            this.showRetryAlert()
          } else {
            window.location.reload()
          }
        }
      })
  }

  showOfflineContentAlert() {
    this.alertService.confirmAlert('ALERT.NO_INTERNET_TITLE', 'ALERT.NO_INTERNET_MESSAGE').then((confirm) => {
      if (confirm) {
        if (this.backButtonSubscription) this.backButtonSubscription.unsubscribe()

        this.navCtrl.navigateRoot('/offline-content')
      } else {
        if (!this.lastStatus) {
          this.showOfflineContentAlert()
        } else {
          window.location.reload()
        }
      }
    })
  }

  endNetworkListener() {
    if (this.networkListener) {
      this.networkListener.then((listener) => {
        listener.remove()
      })
    }
  }

  ngOnDestroy() {
    if (this.networkListener) {
      this.networkListener.then((listener) => {
        listener.remove()
      })
    }
  }
}
