import { Injectable } from "@angular/core"
import { Author } from "src/app/lib/interfaces/author/author.model"
import { env } from "src/environments/env"
import { ApiService } from "../api.service"
import { String } from "typescript-string-operations"

@Injectable()
export class AuthorApiService extends ApiService {
  async getAuthors(): Promise<Author[]> {
    return new Promise(resolve => {
      this.getQuery(env.apiRouteAuthors).subscribe(res => resolve(res.data))
    })
  }

  async getAuthor(id: number): Promise<Author> {
    return new Promise(resolve => {
      this.getQuery(String.format(env.apiRouteAuthor, id)).subscribe(resolve)
    })
  }

  updateAuthor(author: Author): Promise<any> {
    return new Promise(resolve => {
      this.updateQuery(String.format(env.apiRouteUpdateAuthor, author.id), author).subscribe(resolve)
    })
  }
}