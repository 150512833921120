import { Action, createReducer, on } from '@ngrx/store'
import * as ChapterActionsActions from './chapter.actions'
import { initialState, ChapterState } from './chapter.state'

const chapterReducer = createReducer(
  initialState,
  on(ChapterActionsActions.loadChapter, (state, { chapterID }) => ({ ...state, id: chapterID, isLoading: true, isLoaded: false })),
  on(ChapterActionsActions.loadChapterSuccess, (state, { chapter }) => ({
    ...state, chapter, isLoading: false, isLoaded: true,
  })),
  on(ChapterActionsActions.toogleFavoritePage, (state, { number, isFavorite }) => 
    ({ ...state, chapter: 
      { ...state.chapter, chapterPages: [...state.chapter.chapterPages.map(value => {
        if (value.pageNumber === number) {
          return { ...value, favorite: isFavorite }
        }
        return value
      })] } })),
  on(ChapterActionsActions.increaseCommentCount, (state) => ({ ...state, chapter: { ...state.chapter, commentsCount: state.chapter.commentsCount + 1 } })),
  on(ChapterActionsActions.reset, () => initialState)
)

export function reducer(state: ChapterState | undefined, action: Action) {
  return chapterReducer(state, action)
} // end_reducer
