export interface ReaderState {
  currentPage: any,
  showLinks: boolean
  readingMode: string,
  quality: string,
  slideHeight: number,
  orientation: string,
  footerHeight: number,
  showSelectorModal: boolean,
  goToPage: number,
  isLastPage: boolean,
  isZoomed: boolean,
  isOffline: boolean,
  isOnPosition: boolean
  lang: number,
} // end_ReaderState

export const initialState: ReaderState = {
  currentPage: 1,
  quality: null,
  readingMode: null,
  showLinks: false,
  slideHeight: 0,
  orientation: 'portrait',
  footerHeight: 0,
  showSelectorModal: false,
  goToPage: 0,
  isLastPage: false,
  isZoomed: false,
  isOffline: false,
  isOnPosition: false,
  lang: null,
} // end_initialState