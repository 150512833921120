import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { ApiService } from '../lib/services/api.service'
import { ModalController, NavController, PopoverController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { GlobalService } from '../lib/services/global.service'
import { Router } from '@angular/router'
import { ToastService } from '../lib/services/toast.service'
import { AlertService } from '../lib/services/alert.service'
import { SK, UtilService } from '../lib/services/util.service'
import { LoadingService } from '../lib/services/loading.service'
import { StorageService } from '../lib/services/storage.service'
import { LocalizationService } from '../lib/services/localization.service'
import { AuthApiService } from '../lib/services/api/modules/auth.api'

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private apiService: ApiService,
    private navCtrl: NavController,
    private translateService: TranslateService,
    private global: GlobalService,
    private toastService: ToastService,
    private alertService: AlertService,
    private storage: StorageService,
    private util: UtilService,
    private router: Router,
    private loadingService: LoadingService,
    private popoverController: PopoverController,
    private localizationService: LocalizationService,
    private authApiService: AuthApiService,
    private modalController: ModalController
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isShownErrorSession: boolean = false

    return next.handle(request).pipe(
      catchError((err: any) => {
        this.loadingService.hide() //Por si acaso xD
        if (err instanceof HttpErrorResponse) {
          try {
            console.log(err)
            console.warn('error -> ' + JSON.stringify(err))
            if (err.error.message === 'LOGGED_OUT') {
              if (!this.global.hasToken) return
              if (!this.global.unlinked) {
                this.global.unlinked = true
                this.authApiService.resetCredentials()
                this.alertService.okAlert('ALERT.DEVICE_UNLINKED', 'ALERT.DEVICE_UNLINKED_EXPLANATION').then(() => {
                  this.navCtrl.navigateRoot('/login')
                })
              }
            } else if (err.error === 'SESSION_NOT_FOUND') {
              if (!isShownErrorSession) {
                isShownErrorSession = true
                this.authApiService.resetCredentials()
                this.navCtrl.navigateRoot('/login')
  
                this.alertService.okAlert('ALERT.SESSION_NOT_FOUND_TITLE', 'ALERT.SESSION_NOT_FOUND_MESSAGE').then(() => {
                  isShownErrorSession = false
                })
              }
            } else if (err.error.message === 'INVALID_TOKEN') {
              if (!isShownErrorSession) {
                isShownErrorSession = true
                this.authApiService.resetCredentials()
                this.navCtrl.navigateRoot('/login')
                this.alertService.okAlert('ALERT.SESSION_ERROR_TITLE', 'ALERT.SESSION_ERROR_MESSAGE').then(() => {
                  isShownErrorSession = false
                })
              }
            } else if (err.error.message === 'MISSING_CREDENTIALS') {
              this.translateService.get('ERROR.MISSING_CREDENTIALS').subscribe((resp) => {
                this.toastService.presentToast(resp)
              })
            } else if (err.error.message === 'CREDENTIALS_ERROR') {
              this.translateService.get('ERROR.CREDENTIALS_ERROR').subscribe((resp) => {
                this.toastService.presentToast(resp)
              })
            } else if (err.error.code === 'PENDING_ACCOUNT_VALIDATION') {
              this.alertService
                .confirmAlert(
                  'ALERT.PENDING_ACCOUNT_VALIDATION_TITLE',
                  'ALERT.PENDING_ACCOUNT_VALIDATION_BODY',
                  'ALERT.PENDING_ACCOUNT_VALIDATION_RESEND',
                  'WORD.CANCEL',
                )
                .then((response) => {
                  if (response) {
                    this.authApiService.resendValidationEmail(err.error.email)
                  }
                })
            } else if (err.error.code === 'PENDING_EMAIL_VALIDATION') {
              this.alertService
                .confirmAlert(
                  'ALERT.PENDING_EMAIL_VALIDATION_TITLE',
                  'ALERT.PENDING_EMAIL_VALIDATION_BODY',
                  'ALERT.PENDING_ACCOUNT_VALIDATION_RESEND',
                  'WORD.CANCEL',
                )
                .then((response) => {
                  if (response) {
                    this.authApiService.resendEmailValidationEmail(err.error.email)
                  }
                })
            } else if (err.error.message === 'SERIE_EXPLICIT_CONTENT') {
              this.alertService
                .okAlert('ALERT.SERIE_EXPLICIT_CONTENT_TITLE', 'ALERT.SERIE_EXPLICIT_CONTENT_BODY')
                .then((response) => {
                  if (response) {
                    this.router.navigate(['/main/tabs/home'], {
                      replaceUrl: true,
                    })
                  }
                })
            } else if (err.error.message === 'EMAIL_ALREADY_TAKEN') {
              this.alertService.okAlert('ALERT.EMAIL_ALREADY_TAKEN_TITLE', 'ALERT.EMAIL_ALREADY_TAKEN_BODY')
            } else if (err.error.message === 'VALIDATION_ERROR') {
              this.alertService.okAlert('ALERT.INVALID_REGISTER_DATA_TITLE', 'ALERT.INVALID_REGISTER_DATA_MESSAGE')
            } else if (err.error.message === 'EMAIL_NOT_FOUND') {
              this.translateService.get('FORGOT.EMAIL_NOT_FOUND').subscribe(resp => {
                this.toastService.presentToast(resp)
              })
            } else if (err.error.message === 'INSUFICIENT_COINS') { 
              this.popoverController.dismiss(false)
              this.alertService
              .confirmAlert('ALERT.INSUFICIENT_COINS_TITLE', 'ALERT.INSUFICIENT_COINS_MESSAGE', 'WORD.YES', 'WORD.NO')
              .then(async (resp) => {
                if (resp) {
                    this.modalController.dismiss(false)
                    this.router.navigate(['/shop']) 
                  }
                })
            } else if (err.error.message === 'PIN_NOT_FOUND') {
              this.alertService.okAlert('ALERT.PIN_NOT_FOUND_TITLE', 'ALERT.PIN_NOT_FOUND_MESSAGE')
            } else if (err.error.message === 'PIN_NOT_MATCH') {
              this.alertService.okAlert('ALERT.PIN_NOT_MATCH_TITLE', 'ALERT.PIN_NOT_MATCH_MESSAGE')
            } else if (err.error.message === 'VERSION_OUTDATED') {
              const lang = this.localizationService.getDefaultLanguage()
              let message = ''

              for (let obj of err.error.data) {
                const code = this.localizationService.getLanguage(obj.lang.toString())

                if (code === lang) {
                  message = obj.description
                  break
                }
              }

              if (err.error.level === 0) {
                this.alertService
                  .confirmAlert('ALERT.NEW_VERSION_TITLE', message, 'ALERT.NEW_VERSION_GO_TO', 'STORE_POPOVER.CANCEL_BUTTON')
                  .then((res) => {
                    if (res) this.util.redirectToStores()
                })//
              } else if (err.error.level === 1) {
                this.navCtrl.navigateRoot(['/update'], { queryParams: { message: message }})
              }
            } else if (err.status === 503) {
              //Mantenimiento
              this.router.navigate(['/maintenance'], { replaceUrl: true })
            } else {
              if (err.statusText === 'Unauthorized') {
                this.router.navigate(['/login'], { replaceUrl: true })
                this.storage.remove(SK.TOKEN)

                this.storage.remove(SK.USER_ID)
                this.storage.remove(SK.SESSION_ID)
              } else if (err.statusText === 'Not Found') {
              } else {
                //this.toastService.presentToast(err.statusText)
              }
            }
            return throwError(err)
          } catch (e) {
            //this.toastService.presentToast(e)
          }
        }
      }),
    )
  }
}
