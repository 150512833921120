import { Injectable } from '@angular/core'
import {
  ActionSheetController,
  PopoverController,
  ModalController,
  ToastController,
  AlertController,
} from '@ionic/angular'
import { LocationStrategy } from '@angular/common'
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
class CloseOverlaysService {
  constructor(
    private actionSheetController: ActionSheetController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private toastController: ToastController,
    private alertController: AlertController,
    private location: LocationStrategy,
    private platform: Platform
  ) {}
  
  private async trigger(): Promise<boolean> {
    const controllers = {
      action: this.actionSheetController,
      alert: this.alertController,
      popover: this.popoverController,
      modal: this.modalController,
      toast: this.toastController,
    } // end_controllers

    for (const index in controllers) {
      let element = await controllers[index]?.getTop()
      if (element) {
        return element.dismiss().then(res => { return res})
      }
    }
    
    return false
  } // end_trigger

  async onBackButton() {
    this.location.onPopState(() => {
      this.trigger()
    })

    this.platform.backButton.subscribeWithPriority(9999, (processNextHandler) => {
      this.trigger().then(res => {
        if (!res) processNextHandler()
      })
    })
  }
}

export default CloseOverlaysService
