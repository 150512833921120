import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  RemoteSetting,
} from '../interfaces/api.interface'
import { env } from '../../../environments/env'
import { String } from 'typescript-string-operations'
import { catchError } from 'rxjs/operators'
import { NetworkHandler } from './network-handler.service'
import { SK } from './util.service'
import { StorageService } from './storage.service'
import { UserPurchasedSubscription } from '../interfaces/user/user.model'
import { StripeCheckoutSession } from '../interfaces/product/product.model'
// import { OrderBy } from './api/api.service'

export enum OrderBy {
  POPULAR = 'popular',
  BEST_RATED = 'bestRated',
  LAST_RELEASES = 'lastReleases',
  TITLE = 'title',
  SEARCHED = 'searched',
}

export enum MESSAGE_STATE {
  SENT = 0,
  RECEIVED = 1,
  READED = 2,
}

export interface ApiCallOptions {
  limit?: number
  orderBy?: OrderBy
  lang?: number
  offset?: number
  customParams?: {}
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    public storage: StorageService,
    public networkHandler: NetworkHandler,
  ) { }

  // #################
  // #### PAYMENTS ###
  // #################
  async createStripeSubscriptionCheckoutSession(productId: string): Promise<StripeCheckoutSession> {
    const userId = await this.storage.get(SK.USER_ID)
    return new Promise((resolve) => {
      this.http
        .get<StripeCheckoutSession>(
          `${env.apiUrl}/${env.apiRouteStripeSubscriptionCheckoutSession}?userId=${userId}&productId=${productId}`,
        )
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  async cancelSubscription(product: any): Promise<any> {
    return new Promise((resolve) => {
      this.http
        .put<any>(`${env.apiUrl}/${env.apiRouteCancelSubscription}`, {
          product: product,
        })
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  async cancelStripeSubscription(externalId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.http
        .delete<boolean>(`${env.apiUrl}/${env.apiRouteStripeCancelSubscription}?externalId=${externalId}`)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  async getUserSubscription(subscriptionId: string): Promise<UserPurchasedSubscription> {
    const userId = await this.storage.get(SK.USER_ID)
    return new Promise((resolve) => {
      this.http
        .get<UserPurchasedSubscription>(
          `${env.apiUrl}/${String.Format(env.apiRouteUserSubscription, userId, subscriptionId)}`,
        )
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  async changeEmail(newEmail: string) {
    return new Promise(async (resolve) => {
      const userId = await this.storage.get(SK.USER_ID)
      this.http
        .put(String.Format(`${env.apiUrl}/${env.apiRouteChangeEmail}`, userId), {
          newEmail,
        })
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  // #################
  // #### REMOTE SETTINGS ####
  // #################

  async getRemoteSettings(): Promise<RemoteSetting[]> {
    return new Promise<RemoteSetting[]>((resolve) => {
      this.http.get<RemoteSetting[]>(String.Format(`${env.apiUrl}/${env.apiRouteGetRemoteSettings}`)).subscribe(
        (res) => {
          resolve(res)
        },
        () => {
          resolve(null)
        },
      )
    })
  }

  async checkChangeEmailKey(email: string, key: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .get<any>(`${env.apiUrl}/${env.apiRouteCheckChangeEmailKey}?email=${email}&key=${key}`)
        .subscribe((res: any) => {
          resolve(res.result)
        })
    })
  }

  async confirmEmailChanged(email: string, key: string): Promise<any> {
    return new Promise<any>((resolve) => {
      this.http
        .put<any>(`${env.apiUrl}/${env.apiRouteConfirmEmailChanged}?email=${email}&key=${key}`, { confirm: true })
        .subscribe((res: any) => {
          resolve(res)
        })
    })
  }
}
