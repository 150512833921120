import { Component, OnInit, Input } from '@angular/core'
import { UserRatingComponent } from '../../popovers/user-rating/user-rating.component'

@Component({
  selector: 'app-rating-star',
  templateUrl: './rating-star.component.html',
  styleUrls: ['./rating-star.component.scss'],
})
export class RatingStarComponent implements OnInit {
  @Input() starIndex = 0
  @Input() rating
  @Input() interactable = false
  @Input() newRating
  @Input() forceDark: boolean
  @Input() size = 'large'

  color: string

  constructor(private userRatingComponent: UserRatingComponent) {}

  ngOnInit() {
    this.color = this.forceDark ? 'primary' : 'warning'
  }

  starButtonPressed() {
    if (!this.interactable) {
      return
    }
    this.userRatingComponent.userRating = this.starIndex
  }
}
