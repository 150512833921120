<ion-content class="ion-padding ion-text-center">
  <h3>{{ 'USER_RATING.OPINION' | translate }}</h3>
  <h4>
    <b>{{ serieName }}</b>
  </h4>

  <app-rating-stars [rating]="userRating" [interactable]="true" size="large"> </app-rating-stars>

  <ion-button
    [disabled]="this.userRating === 0"
    mode="md"
    class="ion-margin"
    (click)="onRateButtonPressed()"
    expand="block"
  >
    {{ 'USER_RATING.RATE' | translate }}
  </ion-button>
  <small>{{ 'USER_RATING.TOTAL_RATES' | translate }}: {{ totalRatings }}</small>
</ion-content>
