<ion-content class="ion-padding">
  <ion-text>
    <p>
      {{ 'SERIE.EXPLICIT_DATE_REQUEST' | translate }}
    </p>
  </ion-text>
  <ion-item>
    <ion-input
      (ionChange)="this.onChange($event)" 
      type="date"
    ></ion-input>
  </ion-item>
  @if (this.badDate) {
    <ion-item lines="none">
      <ion-text color="danger">
        {{ 'SERIE.EXPLICIT_BAD_DATE' | translate }}
      </ion-text>
    </ion-item>
  }
  <ion-text>
    <p>
      {{ 'SERIE.EXPLICIT_DATE_INFO' | translate }}
    </p>
  </ion-text>
  <ion-text>
    <i>
      <strong>
        {{ 'SERIE.EXPLICIT_PRIVACY_NOTE' | translate }}
      </strong>
    </i>
    <i>
      {{ 'SERIE.EXPLICIT_PRIVACY_DESC' | translate }}
    </i>
  </ion-text>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-padding">
    <ion-buttons slot="start">
      <ion-button (click)="this.cancel()">
        <ion-label>{{ 'WORD.CANCEL' | translate }}</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button 
        mode="md"
        class="touchable ion-margin-end"
        slot="end"
        color="warning"
        fill="solid" 
        (click)="this.confirm()">
        <ion-label>{{ 'WORD.ACCEPT' | translate }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>