import { Injectable } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { ChapterAccessComponent } from '../../components/popovers/chapter-access/chapter-access.component'
import { GlobalService, USER_TYPE } from './global.service'
import { SK, UtilService } from './util.service'
import { ToastService } from './toast.service'
import { LocalizationService } from './localization.service'
import { String } from 'typescript-string-operations'
import { StorageService } from './storage.service'
import { SerieApiService } from './api/modules/serie.api'
import { UserApiService } from './api/modules/user.api'
import { ChapterProductStatus } from '../interfaces/chapter/chapter.model'
import { AUTHOR_LEVEL } from '../interfaces/author/author.model'
import { ProductApiService } from './api/modules/product.api'

@Injectable({
  providedIn: 'root',
})
export class PurchaseChapterService {
  chapterProductStatus: ChapterProductStatus

  constructor(
    public global: GlobalService,
    private storage: StorageService,
    private popoverController: PopoverController,
    public util: UtilService,
    private toastService: ToastService,
    private localizationService: LocalizationService,
    private serieApiService: SerieApiService,
    private userApiService: UserApiService,
    private productApiService: ProductApiService
  ) {}

  async handlePurchase(chapterId: number): Promise<boolean> {
    // Cargamos el ChapterProductStatus para ver si está comprado, su precio, si es exclusivo,
    // si es gratuito, si es el primero que compras de esta serie...
    this.chapterProductStatus = await this.userApiService.getUserChapterProductStatus(chapterId)
    //Lo primero, estas suscrito?
    if (!this.global.getGlobalUser()) {
      this.global.setGlobalUser(await this.userApiService.getUser(await this.storage.get(SK.USER_ID)))
    }
    if (this.global.getGlobalUser()?.type === USER_TYPE.AUTHOR) {
      if (this.global.getGlobalUser()?.author?.level === AUTHOR_LEVEL.UNVERIFIED) {
        const serie = await this.serieApiService.getSerieDetail(this.chapterProductStatus.serieId)
        if (serie.authors.find((x) => x.id === this.global.getGlobalUser()?.author?.id)) {
          return true
        } else {
        }
      } else {
        return true
      }
    }

    if (this.global.isUserSubscribed() && this.chapterProductStatus.availableForSubscription) {
      return true
    }

    let autopurchase = false

    let seriesAutopurchase = await this.storage.get(SK.SERIES_AUTOPURCHASE)
    if (seriesAutopurchase && seriesAutopurchase.length > 0) {
      const serie = seriesAutopurchase.find((x) => x.key === this.chapterProductStatus.serieId)
      if (serie) {
        if (serie.value !== null || serie.value !== '') {
          autopurchase = serie.value
        } else {
          serie.value = false
          autopurchase = false
        }
      } else {
        seriesAutopurchase.push({
          key: this.chapterProductStatus.serieId,
          value: false,
        })
        autopurchase = false
      }
    } else {
      seriesAutopurchase = []
      seriesAutopurchase.push({
        key: this.chapterProductStatus.serieId,
        value: false,
      })
      autopurchase = false
    }
    await this.storage.set(SK.SERIES_AUTOPURCHASE, seriesAutopurchase)

    if (!this.chapterProductStatus.purchaseId) {
      if (this.chapterProductStatus.free || autopurchase) {
        return new Promise(async (resolve) => {
          this.productApiService
            .buyChapter(this.chapterProductStatus.id)
            .then(async () => {
              if (!this.chapterProductStatus.free) {
                let text = await this.localizationService.i18n('PURCHASE_CHAPTER.SUCCESS')
                let extra = await this.localizationService.i18n('WORD.EXTRA')
                this.toastService.presentToast(
                  String.Format(
                    text,
                    !this.chapterProductStatus.isExtra ? this.chapterProductStatus.chapterNumber : extra,
                  ),
                )
              }
              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        })
      } else {
        return new Promise(async (resolve) => {
          const popover = await this.popoverController.create({
            component: ChapterAccessComponent,        
            cssClass: 'hideLinksPopover',
            backdropDismiss: false,
            componentProps: {
              chapterProductStatus: this.chapterProductStatus,
            },
            showBackdrop: true,
            translucent: true,
            animated: false,
          })

          popover.onDidDismiss().then(async (res) => {
            if (res !== null && res.data !== undefined) {
              if (res.data == true) {
                let text = await this.localizationService.i18n('PURCHASE_CHAPTER.SUCCESS')
                let extra = await this.localizationService.i18n('WORD.EXTRA')
                this.toastService.presentToast(
                  String.Format(
                    text,
                    !this.chapterProductStatus.isExtra ? this.chapterProductStatus.chapterNumber : extra,
                  ),
                )
                resolve(true)
              } else {
                resolve(false)
              }
            } else {
              resolve(false)
            }
          })

          await popover.present()
        })
      }
    } else {
      return true
    }
  }
}
