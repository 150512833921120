import { Injectable } from '@angular/core'
import { ToastController } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toast: any
  constructor(private toastController: ToastController) {}

  async presentToast(message: any) {
    this.toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'top',
      mode: 'md',
    })
    this.toast.present()
  }

  async dismissToast() {
    if (this.toast) {
      await this.toast.dismiss()
    }
  }

  /*async presentOfflineToast() {
    const toast = await this.toastController.create({
      message: "No hay conexión a Internet. ¿Quieres continuar leyendo los cómics descargados?",
      duration: 0,
      position: 'middle',
      mode: "md",
      buttons: ["Si", "No"],
      header: "offline"
    });
    toast.present();
  }*/
}
