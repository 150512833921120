import { Component, OnInit } from '@angular/core'
import { NavParams, PopoverController } from '@ionic/angular'
import { GlobalService } from '../../../lib/services/global.service'
import { SK } from 'src/app/lib/services/util.service'
import { ProductsService } from '../../../lib/services/products.service'
import { Router } from '@angular/router'
import { StorageService } from 'src/app/lib/services/storage.service'
import { UserApiService } from 'src/app/lib/services/api/modules/user.api'
import { ChapterProductStatus } from 'src/app/lib/interfaces/chapter/chapter.model'
import { ProductApiService } from 'src/app/lib/services/api/modules/product.api'

@Component({
  selector: 'app-chapter-access',
  templateUrl: './chapter-access.component.html',
  styleUrls: ['./chapter-access.component.scss'],
})
export class ChapterAccessComponent implements OnInit {
  chapterProductStatus: ChapterProductStatus
  isAutoPurchase: boolean = false

  constructor(
    navParams: NavParams,
    private popoverController: PopoverController,
    public global: GlobalService,
    private storage: StorageService,
    private productsService: ProductsService,
    private router: Router,
    private userApiService: UserApiService,
    private productApiService: ProductApiService
  ) {
    this.chapterProductStatus = navParams.get('chapterProductStatus')

    if (!this.global.getGlobalUser()) {
      this.popoverController.dismiss(false)
      this.router.navigate(['/serie-detail/', this.chapterProductStatus.serieId], { replaceUrl: true })
    }
  }

  dismiss() {
    this.popoverController.dismiss(false)
  }

  async ngOnInit() {
    let seriesAutopurchase: any[] = await this.storage.get(SK.SERIES_AUTOPURCHASE)
    if (!seriesAutopurchase || !seriesAutopurchase.length) {
      seriesAutopurchase = []
      seriesAutopurchase.push({
        key: this.chapterProductStatus.serieId,
        value: false,
      })

      await this.storage.set(SK.SERIES_AUTOPURCHASE, seriesAutopurchase)

      this.isAutoPurchase = seriesAutopurchase.find((x) => x.key == this.chapterProductStatus.serieId).value
    } else {
      this.isAutoPurchase = seriesAutopurchase.find((x) => x.key == this.chapterProductStatus.serieId).value
    }
  }

  async purchaseChapter() {
    this.productApiService
      .buyChapter(this.chapterProductStatus.id)
      .then(async () => {
        this.global.setGlobalUser(await this.userApiService.getUser(await this.storage.get(SK.USER_ID)))
        this.popoverController.dismiss(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async openInfinity() {
    this.dismiss()
    this.productsService.subscriptionOpened = true
    this.router.navigate(['/subscribe'])
  }

  async autoPurchase(event) {
    let seriesAutopurchase = await this.storage.get(SK.SERIES_AUTOPURCHASE)
    if (!seriesAutopurchase || !seriesAutopurchase.length) {
      seriesAutopurchase = []
      seriesAutopurchase.push({
        key: this.chapterProductStatus.serieId,
        value: false,
      })
    }
    if (event.target.checked) {
      seriesAutopurchase.find((x) => x.key == this.chapterProductStatus.serieId).value = false
    } else {
      seriesAutopurchase.find((x) => x.key == this.chapterProductStatus.serieId).value = true
    }

    await this.storage.set(SK.SERIES_AUTOPURCHASE, seriesAutopurchase)
  }
}
