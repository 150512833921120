export interface VolumeState {
  volume: any,
  isLoading: boolean,
  isLoaded: boolean
} // end_SerieState

export const initialState: VolumeState = {
  volume: null,
  isLoading: false,
  isLoaded: false
} // end_initialState