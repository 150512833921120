import { Injectable } from "@angular/core"
import { ApiService } from "../api.service"
import { env } from "src/environments/env"
import { FindOptionsDto } from "src/app/lib/interfaces/find-options.dto"
import { String } from "typescript-string-operations"
import { Comment } from "src/app/lib/interfaces/comment/comment.model"

@Injectable()
export class CommentApiService extends ApiService {
  async sendCommentChapter(chapterId: number, comment: string, parentMsgId: number, isSpoiler: boolean): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(`${env.apiRouteSendCommentChapter}/${chapterId}`, {
        comment: comment,
        parentCommentId: parentMsgId,
        isSpoiler: isSpoiler,
      }).subscribe(resolve)
    })
  }

  async getCommentsChapter(chapterId: number, findOptions: FindOptionsDto): Promise<Comment[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(String.format(`${env.apiRouteGetCommentsChapter}/${chapterId}`), await this.parseQueryParams(findOptions))
      .subscribe(res => resolve(res.data))
    })
  }

  async getComment(commentId: number): Promise<Comment> {
    return new Promise(resolve => {
      this.getQuery(`${env.apiRouteGetComment}/${commentId}`).subscribe(res => resolve(res.data))
    })
  }

  async getCommentsSerie(serieId: number, findOptions: FindOptionsDto): Promise<Comment[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(`${env.apiRouteGetCommentsSerie}/${serieId}`, await this.parseQueryParams(findOptions))
      .subscribe(res => resolve(res.data))
    })
  }

  async getCommentReplies(commentId: number, findOptions: FindOptionsDto): Promise<Comment[]> {
    return new Promise(async resolve => {
      this.getQueryWithParams(`${env.apiRouteGetCommentReplies}/${commentId}`, await this.parseQueryParams(findOptions))
      .subscribe(res => resolve(res.data))
    })
  }

  async markUnmarkAsSpoilerComment(commentId: number, mark: boolean): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteMarkSpoilerComment, commentId), { mark }).subscribe(resolve)
    })
  }

  async likeUnlikeComment(commentId: number, like: boolean): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteLikeComment, commentId), { like }).subscribe(resolve)
    })
  }

  async reportUnreportComment(commentId: number, report: boolean): Promise<any> {
    return new Promise(resolve => {
      this.postQuery(`${env.apiRouteReportComment}/${commentId}`, { report }).subscribe(resolve)
    })
  }

  uploadImage(image: FormData, idComment: number) {
    return new Promise(resolve => {
      this.postQuery(String.format(env.apiRouteCommentImage, idComment), image).subscribe(resolve)
    })
  }
}