import { createFeatureSelector, createSelector } from "@ngrx/store"
import { AuthState } from "./auth.state"

export const authSelector = createFeatureSelector<AuthState>('auth')

export const selectUser = createSelector(
  authSelector,
  (state: AuthState) => state.user
)

export const selectCanEnterShop = createSelector(
  authSelector,
  (state: AuthState) => state.canEnterShop
)

export const selectPushNotifEnabled = createSelector(
  authSelector,
  (state: AuthState) => state.pushNotifEnabled
)