<ion-content class="ion-padding ion-text-center">
  <h3>{{ 'ALERT.SERIE_EXPLICIT_CONTENT_TITLE' | translate }}</h3>
  <p>
    {{ 'SERIE.EXPLICIT_FAMILY_MODE_REQUEST' | translate }}
  </p>

  <ion-buttons class="ion-justify-content-center">
    <ion-button 
      mode="md"
      class="touchable ion-margin-end"
      color="warning"
      fill="solid" 
      (click)="this.confirm()">
      <ion-label>{{ 'WORD.ACCEPT' | translate }}</ion-label>
    </ion-button>
    <ion-button (click)="this.cancel()" >
      <ion-label>{{ 'WORD.CANCEL' | translate }}</ion-label>
    </ion-button>
  </ion-buttons>
</ion-content>
