import { Component, OnInit } from '@angular/core'
import { ModalController, ViewWillLeave } from '@ionic/angular'
import { GlobalService } from 'src/app/lib/services/global.service'
import { SK, UtilService } from 'src/app/lib/services/util.service'
import { ProductsService } from '../../lib/services/products.service'
import { LoadingService } from '../../lib/services/loading.service'
import { StorageService } from 'src/app/lib/services/storage.service'
import { UserApiService } from 'src/app/lib/services/api/modules/user.api'
import { PRODUCT_PLATFORM } from 'src/app/lib/interfaces/product/product.model'
import { Router } from '@angular/router'

@Component({
  selector: 'app-shop',
  templateUrl: './shop.page.html',
  styleUrls: ['./shop.page.scss'],
})
export class ShopPage implements OnInit, ViewWillLeave {
  public openModal: boolean = false
  public purchasedIAP: any = null

  constructor(
    private storage: StorageService,
    private modalController: ModalController,
    public global: GlobalService,
    public util: UtilService,
    public productsService: ProductsService,
    public loadingService: LoadingService,
    private userApiService: UserApiService,
    private router: Router
  ) {}

  async ionViewWillLeave() {
    this.productsService.shopOpened = false
    const userId = await this.storage.get(SK.USER_ID)
    const user = await this.userApiService.getUser(userId)
    this.global.setGlobalUser(user)
  }

  async ngOnInit() {
    this.productsService.shopOpened = true
  }

  dismiss() {
    this.modalController.dismiss()
  }

  async purchaseIAP(iap: any) {
    const platform = this.util.getProductPlatform()

    if (platform === PRODUCT_PLATFORM.IOS || platform === PRODUCT_PLATFORM.ANDROID) {
      const res = await this.productsService.purchaseMobile(iap.id)

      if (res) {
        this.purchasedIAP = iap
        this.openModal = true
      }
      
    } else if (platform === PRODUCT_PLATFORM.STRIPE) {
      this.productsService.purchaseDesktop(iap.id)
    }
  }

  onBackClick() {
    this.modalController.dismiss()
  }

  public closeModal() {
    this.openModal = false
    this.router.navigate(['/'])
  }
}
