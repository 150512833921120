import { createAction, props } from "@ngrx/store"

export const setEpubLang = createAction(
  "[Epub Reader] Set Epub Lang",
  props<{ lang: number }>()
)

export const setPercent = createAction(
  "[Epub Reader] Set Percent",
  props<{ percent: { value: number, slide: boolean } }>()
)

export const setIsSwiping = createAction(
  "[Epub Reader] Set Is Swiping",
  props<{ isSwiping: boolean }>()
)

export const setPosition = createAction(
  "[Epub Reader] Set Position",
  props<{ position: { current: number, total: number } }>()
)

export const setIsReady = createAction(
  "[Epub Reader] Set Is Ready",
  props<{ isReady: boolean }>()
)

export const setLocations = createAction(
  "[Epub Reader] Set Locations",
  props<{ locations: { cfi: string, page: number }[] }>()
)

export const setFontSize = createAction(
  "[Epub Reader] Set Font Size",
  props<{ fontSize: number }>()
)

export const reset = createAction(
  "[Epub Reader] Reset"
)