import { Injectable } from '@angular/core'
import { AlertController } from '@ionic/angular'
import { LocalizationService } from './localization.service'
import { UtilService } from './util.service'
import { Platform } from '@ionic/angular'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alert: any
  constructor(
    private alertCtrl: AlertController,
    private localizationService: LocalizationService,
    private util: UtilService,
    private platform: Platform,
    private router: Router
  ) {}

  async basicAlert(header, message) {
    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)

    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        keyboardClose: false,
        backdropDismiss: true,
      })
      this.alert.present()
    }
  }

  async okAlert(header, message) {
    let resolveFunction: (boolean) => void
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve
    })

    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)
    let ok = await this.localizationService.i18n('WORD.OK')

    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        buttons: [
          {
            text: ok,
            handler: () => {
              this.alert = null
              resolveFunction(true)
            },
          },
        ],
        keyboardClose: false,
        backdropDismiss: false,
      })
      await this.alert.present()
      return promise
    }
  }

  public existAlert() {
    return this.alert != null
  }

  async confirmAlertWithCheckbox(
    header,
    message,
    checkText,
    textButtonAccept = undefined,
    textButtonCancel = undefined,
  ) {
    let resolveFunction: (confirm: boolean, checkboxChecked: boolean) => void
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve
    })

    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)
    //let checkT = await this.localizationService.i18n(checkText)
    let yes = await this.localizationService.i18n(textButtonAccept ? textButtonAccept : 'WORD.YES')
    let no = await this.localizationService.i18n(textButtonCancel ? textButtonCancel : 'WORD.NO')



    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        inputs: [
          {
            name: 'remember',
            type: 'checkbox',
            label: checkText,
            checked: false,
          },
        ],
        buttons: [
          {
            text: no,
            role: 'cancel',
            handler: () => {
              this.alert = null
              resolveFunction(false, true)
            },
          },
          {
            text: yes,
            handler: () => {
              this.alert = null
              resolveFunction(true, true)
            },
          },
        ],
        keyboardClose: false,
        backdropDismiss: false,
      })
      await this.alert.present()
      return promise
    }
  }

  async textareaAlert(header: string, text: string, placeholder: string) {
    let resolveFunction: (data: any) => void
    const promise = new Promise<any>((resolve) => {
      resolveFunction = resolve
    })

    let headerT = await this.localizationService.i18n(header)
    let placeholderT = await this.localizationService.i18n(placeholder)
    let save = await this.localizationService.i18n('WORD.SAVE')
    let cancel = await this.localizationService.i18n('WORD.CANCEL')
    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        cssClass: this.util.shouldModalsAreFullScreen() ? 'annotations-alert' : 'annotations-alert-mobile',
        inputs: [
          {
            name: 'annotations',
            value: text,
            type: 'textarea',
            cssClass: this.util.shouldModalsAreFullScreen() ? 'special-textarea' : 'special-textarea-mobile',
            placeholder: text && text.length > 0 ? null : placeholderT,
          },
        ],
        buttons: [
          {
            text: cancel,
            role: 'cancel',
            handler: () => {
              this.alert = null
              resolveFunction(null)
            },
          },
          {
            text: save,
            handler: (alertData) => {
              this.alert = null
              resolveFunction(alertData)
            },
          },
        ],
        keyboardClose: true,
        backdropDismiss: false,
      })
      await this.alert.present()
      return promise
    }
  }

  async confirmAlert(header, message, textButtonAccept = undefined, textButtonCancel = undefined) {
    console.error(this.alert)
    if (this.alert) {
      this.alert = null
    }
    let resolveFunction: (confirm: boolean) => void
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve
    })


    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)
    let yes = await this.localizationService.i18n(textButtonAccept ? textButtonAccept : 'WORD.YES')
    let no = await this.localizationService.i18n(textButtonCancel ? textButtonCancel : 'WORD.NO')

    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        buttons: [
          {
            text: no,
            role: 'cancel',
            handler: () => {
              this.alert = null
              resolveFunction(false)
            },
          },
          {
            text: yes,
            handler: () => {
              this.alert = null
              resolveFunction(true)
            },
          },
        ],
        keyboardClose: false,
        backdropDismiss: false,
        cssClass: 'multiLineAlert'
      })

      await this.alert.present()
      return promise
    }
  }

  async confirmAlertWithThreeBtn(header, message, textButtonAccept = undefined, textButtonCancel = undefined, textThirdButton = undefined) {
    if (this.alert) {
      this.alert = null
    }
    let resolveFunction: (confirm: boolean) => void
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve
    })


    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)
    let yes = await this.localizationService.i18n(textButtonAccept ? textButtonAccept : 'WORD.YES')
    let no = await this.localizationService.i18n(textButtonCancel ? textButtonCancel : 'WORD.NO')
    const third = await this.localizationService.i18n(textThirdButton ? textThirdButton : 'WORD.NO')

    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        buttons: [
          {
            text: no,
            handler: () => {
              this.alert = null
              resolveFunction(false)
            },
          },
          {
            text: third,
            role: 'cancel',
            handler: () => {
              this.alert = null
              resolveFunction(null)
            },
          },
          {
            text: yes,
            handler: () => {
              this.alert = null
              resolveFunction(true)
            },
          }
        ],
        keyboardClose: false,
        backdropDismiss: false,
        cssClass: 'multiLineAlert'
      })

      await this.alert.present()
      return promise
    }
  }

  async oneButtonAlert(header, message, textButton = undefined) {
    let resolveFunction: (confirm: boolean) => void
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve
    })

    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)
    let ok = await this.localizationService.i18n(textButton ? textButton : 'WORD.OK')

    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        buttons: [
          {
            text: ok,
            handler: () => {
              this.alert = null
              resolveFunction(true)
            },
          },
        ],
        keyboardClose: false,
        backdropDismiss: false,
      })
      await this.alert.present()
      return promise
    }
  }

  async inputAlert(header, message, placeholderInput = null, textButton = null) {
    let resolveFunction: (data: any) => void
    const promise = new Promise<any>((resolve) => {
      resolveFunction = resolve
    })
    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)
    let ok = await this.localizationService.i18n(textButton ? textButton : 'WORD.OK')
    let cancel = await this.localizationService.i18n('WORD.CANCEL')
    let placeholderT = await this.localizationService.i18n(placeholderInput)
    let placeholder = placeholderInput && (placeholderInput !== '' ? placeholderT : '')

    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        inputs: [
          {
            name: 'data',
            type: 'text',
            placeholder: placeholder,
          },
        ],
        buttons: [
          {
            text: cancel,
            role: 'cancel',
            handler: () => {
              this.alert = null
              resolveFunction(null)
            },
          },
          {
            text: ok,
            handler: (alertData) => {
              this.alert = null
              resolveFunction(alertData)
            },
          },
        ],
        keyboardClose: false,
        backdropDismiss: false,
      })
      await this.alert.present()
      return promise
    }
  }

  async customAlert(
    header: string,
    message: string,
    okButton: string,
    cancelButton: string,
    okCallback,
    cancelCallback,
  ) {
    let headerT = await this.localizationService.i18n(header)
    let messageT = await this.localizationService.i18n(message)
    let ok = await this.localizationService.i18n(okButton)
    let cancel = await this.localizationService.i18n(cancelButton)

    if (!this.alert) {
      this.alert = await this.alertCtrl.create({
        header: headerT,
        message: messageT,
        buttons: [
          {
            text: cancel,
            role: 'cancel',
            handler: () => {
              this.alert = null
              cancelCallback()
            },
          },
          {
            text: ok,
            handler: () => {
              this.alert = null
              okCallback()
            },
          },
        ],
        keyboardClose: false,
        backdropDismiss: false,
      })

      await this.alert.present()
    }
  }
}
