import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { BuyVolumeComponent } from "./buy-volume.component"
import { VolumeChaptersListComponent } from "./volume-chapters-list/volume-chapters-list.component"
import { ScrollingModule } from "@angular/cdk/scrolling"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { IonicModule } from "@ionic/angular"
import { TranslateModule } from "@ngx-translate/core"
import { DirectivesModule } from "src/app/lib/directives/directives.module"
import { PipesModule } from "src/app/lib/pipes/pipes.module"
import { PurchaseSuccessComponent } from "./purchase-success/purchase-success.component"
import { AllVolumesPurchasedComponent } from "./all-volumes-purchased/all-volumes-purchased.component"

@NgModule({
  declarations: [
    BuyVolumeComponent,
    VolumeChaptersListComponent,
    PurchaseSuccessComponent,
    AllVolumesPurchasedComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    DirectivesModule,
    TranslateModule.forChild(),
    RouterModule,
    ScrollingModule,
  ],
  exports: [
    BuyVolumeComponent,
    VolumeChaptersListComponent,
    PurchaseSuccessComponent,
    AllVolumesPurchasedComponent
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class BuyVolumeModule {}