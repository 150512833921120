import { Injectable } from "@angular/core"
import { Type } from "src/app/lib/interfaces/type/type.model"
import { env } from "src/environments/env"
import { SK } from "../../util.service"
import { ApiService } from "../api.service"

@Injectable()
export class TypeApiService extends ApiService {
  async getTypes(): Promise<Type[]> {
    return new Promise(async resolve => {
      const lang = await this.storage.get(SK.APP_LANG)
      this.getQueryWithParams(env.apiRouteTypes, await this.parseQueryParams({ lang })).subscribe(res => resolve(res.data))
    })
  }
}