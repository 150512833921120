import { Observable, from } from "rxjs"
import { switchMap } from "rxjs/operators"
import { Chapter, Volume } from "src/app/lib/interfaces/api.interface"
import { SK } from "../../util.service"
import { ApiService } from "../api.service"
import { String } from "typescript-string-operations"
import { Injectable } from "@angular/core"
import { env } from "src/environments/env"
import { SerieDetail } from "src/app/lib/interfaces/serie/serie.model"


@Injectable()
export class ChapterApiService extends ApiService {
  getChapterRX(chapterId: number, lang: number, getLinks: boolean = false): Observable<Chapter> {
    return from(Promise.all([
      this.storage.get(SK.IMAGE_QUALITY),
      this.storage.get(SK.USER_ID),
    ])).pipe(
      switchMap(([q, userId]) => {
        return this.getQuery(`${String.format(env.apiRouteChapter, chapterId)}?lang=${lang}&q=${q}&getLinks=${getLinks}&userId=${userId}`)
      }))
  }

  public async getZipThumbs(chapter: Chapter, volume: Volume, serie: SerieDetail, hash: string) {
    const zipThumbsUrl = `${env.apiUrl}/${String.Format(env.apiRouteChapterThumbs, chapter.id)}?volumeId=${
      volume.id
    }&serieId=${serie.id}&lang=${chapter.lang}&defaultLang=${
      serie.lang
    }&forceReload=${false}&hash=${hash}`

    return new Promise(async (resolve, reject) => {
      this.http
      .get(zipThumbsUrl, { responseType: 'blob'})
      .subscribe(
        (res) => {
          resolve(res)
        },
        (err) => {
          reject(err)
        },
      )
    })
  }

  async getChapter(chapterId: number, getLinks: boolean = false): Promise<Chapter> {
    const appLang = await this.storage.get(SK.APP_LANG)
    const imageQuality = await this.storage.get(SK.IMAGE_QUALITY)
    const userId = await this.storage.get(SK.USER_ID)

    return new Promise(resolve => {
      this.getQuery(`${env.apiUrl}/${String.format(
        env.apiRouteChapter,
        chapterId,
      )}?lang=${appLang}&q=${imageQuality}&getLinks=${getLinks}&userId=${userId}`).subscribe(resolve)
    })
  }

  async getEpub(serieId: number, volumeId: number, chapterId: number, lang:number, fileName: string): Promise<Blob> {
    return new Promise(resolve => {
      this.getBlob(`${env.apiUrl}/static/s/${serieId}/c/${volumeId}/ch/${chapterId}/l/${lang}/${fileName}`).subscribe(resolve)
    })
  }
}