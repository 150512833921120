export interface SerieState {
  id: number;
  isLoading: boolean;
  isLoaded: boolean;
  serie: any;
} // end_SerieState

export const initialState: SerieState = {
  id: null,
  isLoading: false,
  isLoaded: false,
  serie: null,
} // end_initialState