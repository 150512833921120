import { ActionReducerMap, MetaReducer } from "@ngrx/store"
import { State } from "./core.state"
import * as ReaderReducer from "./reader/reader.reducer"
import * as SerieReducer from "./serie/serie.reducer"
import * as VolumeReducer from "./volume/volume.reducer"
import * as ChapterReducer from "./chapter/chapter.reducer"
import * as UserChapterStatusReducer from "./user-chapter-status/user-chapter-status.reducer"
import * as AuthReducer from "./auth/auth.reducer"
import * as EpubReaderReducer from "./epub-reader/epub-reader.reducer"

export const reducers: ActionReducerMap<State> = {
  reader: ReaderReducer.reducer,
  serie: SerieReducer.reducer,
  volume: VolumeReducer.reducer,
  chapter: ChapterReducer.reducer,
  userChapterStatus: UserChapterStatusReducer.reducer,
  auth: AuthReducer.reducer,
  epubReader: EpubReaderReducer.reducer
}

export const metaReducers: MetaReducer<State>[] = []