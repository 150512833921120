import { Component, OnInit } from "@angular/core";
import { SK, UtilService } from "src/app/lib/services/util.service";
import { GlobalService } from "src/app/lib/services/global.service";
import { StorageService } from "src/app/lib/services/storage.service";

@Component({
    selector: 'app-suggest-download',
    templateUrl: './suggest-download.component.html',
    styleUrls: ['./suggest-download.component.scss'],
  })

export default class SuggestDownload implements OnInit {
    constructor(
      private utilService: UtilService,
      public globalService: GlobalService,
      private storageService: StorageService
    ) {}

    currentDate = this.utilService.generateIntCurDate()

    async ngOnInit() {
      if (this.utilService.isMobileWeb()) {
        const suggestTime = await this.storageService.get(SK.SUGGEST_TIME)

        if (suggestTime < this.currentDate) this.globalService.setIsSuggestedOpen(true)
      } // end_if
    } // end_ngOnInit

    public goToDownloadPage(): void {
      this.utilService.redirectToStores()
    } // end_goToDownloadPage

    public close(): void {
      this.storageService.set(SK.SUGGEST_TIME, this.currentDate)
      this.globalService.setIsSuggestedOpen(false)
    } // end_close
} // end_SuggestDownload