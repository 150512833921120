import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { PurchaseChapterService } from '../services/purchase-chapter.service'

@Injectable({
  providedIn: 'root',
})
export class ChapterAccessGuard  {
  constructor(private purchaseChapterService: PurchaseChapterService) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const value = Promise.resolve(this.purchaseChapterService.handlePurchase(next.params.chapterId))

    return value
  }
}
