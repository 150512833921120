import { Action, createReducer, on } from '@ngrx/store'
import * as SerieActions from './serie.actions'
import { initialState, SerieState } from './serie.state'

const serieReducer = createReducer(
  initialState,
  on(SerieActions.loadSerie, (state, { serieID }) => ({ ...state, id: serieID, isLoading: true, isLoaded: false })),
  on(SerieActions.loadSerieSuccess, (state, { serie }) => ({ ...state, serie, isLoading: false, isLoaded: true })),
  on(SerieActions.reset, () => initialState)
)

export function reducer(state: SerieState | undefined, action: Action) {
  return serieReducer(state, action)
} // end_reducer
