<ion-header translucent style="margin: auto">
  <ion-toolbar>
    <div class = "headerToolbar">
      <ion-buttons slot="start">
        <ion-back-button class="backButton" text="{{ 'WORD.BACK' | translate }}" defaultHref="main/tabs/home">
        </ion-back-button>
      </ion-buttons>
  
      <ng-content></ng-content>
    </div>
  </ion-toolbar>
</ion-header>
