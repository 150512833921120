import { User } from "src/app/lib/interfaces/user/user.model"

export interface AuthState {
  user: User,
  canEnterShop: boolean
  pushNotifEnabled: boolean
}

export const initialState: AuthState = {
  user: null,
  canEnterShop: false,
  pushNotifEnabled: false
}